import {
    ADD_DRIVER_SUCCESS_ACTION,
    VERIFY_DRIVER_OTP_SUCCESS_ACTION,
    DRIVER_SEND_OTP_SUCCESS_ACTION,
    CHANGE_SCREEN_ACTION
} from './actions'


const initialState = {
    processing: false,
    screen: 'addDriver'
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case ADD_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                screen: 'profile',
                driverDetails: action.payload
            }
        case DRIVER_SEND_OTP_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                screen: 'otp',
            }

        case VERIFY_DRIVER_OTP_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: false,
                screen: 'addDriver',
            }
        case CHANGE_SCREEN_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                screen: 'addDriver'
            }
        default:
            return {
                ...state
            }
    }
}