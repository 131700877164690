import React, { Component } from "react";
import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";
import t from "tcomb-form";
import myCustomTemplate from "../common/customTemplate";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import OpenAgentProfile from "./OpenAgentProfile";
import Navbar3 from "../../components/Navbar2";
// import OpenAgentMain from "./OpenAgentMain";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Navbar2 from "../../components/Navbar";
import "./style.css";
import { styles } from "../common/styles";
import Footer from "../../components/Footer";
import Container from "react-bootstrap/Container";

const Form = t.form.Form;

const otpField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 6;
});

const OTPfieldstruct = t.struct({
  agentOTP: otpField,
});

const options = {
  fields: {
    agentOTP: {
      template: myCustomTemplate,

      config: {
        placeholder: "One time password",
        errorMessage: "Invalid OTP",
        labelHide: true,
        className: "otpparaconfig",
        // label: 'Please enter OTP below to verify your mobile number',
        type: "number",
      },
    },
  },
};

class RegisterOTP extends Component {
  constructor() {
    super();
    this.state = {
      // otpsection: true,
      // profilesection: false,
      options: options,
      otpvalue: {
        agentOTP: "",
        show: false,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(OTPfieldstruct2, path) {
    // this.refs.form.getValue()
    this.refs.form.getComponent(path).validate();
  }
  submit(event) {
    this.setState({
      show: true,
    });
    event.preventDefault();
    var otpvalue = this.refs.form.getValue();

    if (otpvalue) {
      this.props.checkotp(otpvalue);
    }
  }
  chngNmbrClick() {
    this.setState({
      otpsections: false,
      mainsection: true,
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      // <div>
      //     <Navbar2></Navbar2>
      //     <div className="bgclragentOTP container-fluid">
      //         <div>
      //             <div>
      //                 <Container>
      //                     <Row>
      //                         <Col xs={12} md={12} sm={12} className="main_columOtp">

      //                             <div className="cardDivOtp">
      //                                 <Card className="cardMainotp">
      //                                     <Card.Body className="forgotpasswordform">

      //                                         <Card.Title className="cardheadotp">
      //                                             <h3 className="agentotphead">Enter OTP</h3>
      //                                         </Card.Title>

      //                                         <p className="regstrOtpPara">
      //                                             Please enter OTP below to verify your mobile number
      //                                         </p>

      //                                         <div className=" hidingcntntotp">
      //                                             <div className="paragraphOTP">

      //                                             </div>

      //                                             <div className="otpFormGrid">
      //                                                 <Form
      //                                                     ref="form"
      //                                                     type={OTPfieldstruct}
      //                                                     options={this.state.options}
      //                                                     otpvalue={this.state.otpvalue}
      //                                                     onChange={(v, path) => this.onChange(v, path)}
      //                                                 />
      //                                                 <Row>
      //                                                     <Col>
      //                                                         <Link to="/login" onClick={() => window.scrollTo(0, 0)}>
      //                                                             <p
      //                                                                 className="otpnmbrchange"
      //                                                             // onClick={this.chngNmbrClick}
      //                                                             >
      //                                                                 Cancel?
      //                                                             </p>
      //                                                         </Link>
      //                                                     </Col>
      //                                                     <Col>
      //                                                         <p
      //                                                             className="resendotp"
      //                                                             onClick={() => this.props.resendOtp()}>
      //                                                             Resend OTP
      //                                                         </p>
      //                                                     </Col>
      //                                                 </Row>
      //                                             </div>

      //                                             <div className="btnAlignotp">
      //                                                 <Button
      //                                                     type="submit"
      //                                                     className="btnotpagent"
      //                                                     style={styles.fleetBtn}
      //                                                     onClick={this.submit}>
      //                                                     Submit
      //                                                 </Button>
      //                                             </div>
      //                                         </div>
      //                                         <div className="tcandpolicy">
      //                                             <p className="tcandpolicyTerm">
      //                                                 By submitting, you agree to{' '}
      //                                                 <Link
      //                                                     className="tcandpolicyTermTC"
      //                                                     to="/terms"
      //                                                     onClick={() => window.scrollTo(0, 0)}>
      //                                                     T&C{' '}
      //                                                 </Link>
      //                                                 and{' '}
      //                                                 <Link
      //                                                     className="tcandpolicyTermTC"
      //                                                     to="/privacy"
      //                                                     onClick={() => window.scrollTo(0, 0)}>
      //                                                     Privacy Policy
      //                                                 </Link>
      //                                             </p>
      //                                         </div>
      //                                     </Card.Body>
      //                                 </Card>
      //                             </div>

      //                         </Col>
      //                     </Row>
      //                 </Container>
      //             </div>
      //         </div>
      //     </div>
      //     <div className="footer">
      //         <Footer />
      //     </div>
      // </div>

      <div>
        <Navbar3></Navbar3>
        <div className="bgcolorLogin">
          <Row>
            <Col xs={12} md={7} sm={12} lg={7}>
              <img
                className="forgotfrstImg"
                src={require("../../assets/img/forgot-password.svg")}
                alt=""
              />
            </Col>
            <Col xs={12} md={5} sm={12} lg={5}>
              <div className="forgotdiv">
                <div>
                  <h3 className="loginrydz">Enter OTP?</h3>
                </div>
                <div>
                  <p className="forppera">
                    Please enter OTP below to verify your mobile number
                  </p>
                </div>

                <div className="loginForm">
                  <Form
                    ref="form"
                    type={OTPfieldstruct}
                    options={this.state.options}
                    otpvalue={this.state.otpvalue}
                    onChange={(v, path) => this.onChange(v, path)}
                  />
                </div>

                <Button
                  type="submit"
                  className="contactpagebtn"
                  style={styles.forgotBtn}
                  onClick={this.submit}
                >
                  Submit
                </Button>
                <div className="otpaccount">
                  Didn't receive OTP?
                  <span
                    className="loginaccounts"
                    onClick={() => this.props.resendOtp()}
                  >
                    Resend OTP
                  </span>
                </div>
            
                <p className="lastdivTerm">
                  By submitting, you agree to{" "}
                  <Link
                    className="lastdivTermTC"
                    to="https://www.rydz.in/terms"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    T&C{" "}
                  </Link>{" "}
                  and{" "}
                  <Link
                    className="lastdivTermTC"
                    to="https://www.rydz.in/privacy"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default RegisterOTP;
