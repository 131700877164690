import {
    FETCH_ATTACHED_CAR_DRIVER_SUCCESS_ACTION,
    FETCH_ATTACHED_CAR_DRIVER_FAIL_ACTION,
    FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION,
    LOC_AUTOCOMPLETE_SUCCESS_ACTION,
    // FETCH_NEARBY_DRIVER_ACTION,
    // FETCH_NEARBY_DRIVER_FAIL_ACTION,
    FETCH_NEARBY_DRIVER_SUCCESS_ACTION,
    SEARCH_USER_SUCCESS_ACTION,
    FETCH_DRIVER_SUCCESS_ACTION,
    FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
    FETCH_HOURLY_PACKAGE_SUCCESS_ACTION
} from './actions'

//import { FETCH_SIGNUP_SUCCESS_ACTION } from '../SignUpContainer/actions'
const initialState = {
    processing: false,
    error: false,
    attachedDvr: [],
    locPrediction: [],
    nearbyDrvrList: [],
    searchUser: [],
    driver: null,
    specailPkgList: [],
    hourlyPkgList: [],
    attachedDvrCount: 0,
    nearbyDrvrCount: 0
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION:
            return {
                ...state,
                processing: true
            }
        case FETCH_ATTACHED_CAR_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                attachedDvr: action.payload.rows,
                attachedDvrCount: action.payload.count
            }
        case FETCH_ATTACHED_CAR_DRIVER_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: true
            }
        case LOC_AUTOCOMPLETE_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                locPrediction: action.payload,
                error: true
            }
        case FETCH_NEARBY_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                nearbyDrvrList: action.payload.rows,
                nearbyDrvrCount: action.payload.count,
                error: true
            }
        case SEARCH_USER_SUCCESS_ACTION:
            return {
                ...state,
                searchUser: action.payload
            }
        case FETCH_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                driver: action.payload
            }
        case FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION:
            return {
                ...state,
                specailPkgList: action.payload
            }
        case FETCH_HOURLY_PACKAGE_SUCCESS_ACTION:
            return {
                ...state,
                hourlyPkgList: action.payload
            }
        default:
            return {
                ...state
            }
    }
}