import { createBrowserHistory } from 'history'
import { put, takeEvery, select } from 'redux-saga/effects'
import {
  FORGOTPASSWORD_INIT_ACTION,
  FETCH_FORGOTPASSWORD_FAIL_ACTION,
  FETCH_FORGOTPASSWORD_SUCCESS_ACTION,
  FORGOT_CHECKOTP_INIT_ACTION,
  FORGOT_CHECKOTP_FAIL_ACTION,
  FORGOT_CHECKOTP_SUCCESS_ACTION,
  FORGOT_RESEND_OTP_INIT_ACTION,
  FORGOT_RESEND_OTP_FAIL_ACTION,
  FORGOT_RESEND_OTP_SUCCESS_ACTION,
  FORGOT_PSWRD_CHANGE_INIT_ACTION,
  FORGOT_PSWRD_CHANGE_FAIL_ACTION,
  FORGOT_PSWRD_CHANGE_SUCCESS_ACTION
} from './actions'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { appConfig } from '../../config'
var btoa = require('Base64').btoa
var history = createBrowserHistory()

function* forgotpassword(value) {


  const OtpValue = {
    mobileNo: '+91' + value.value.mobileNo
  }



  try {
    let res = yield fetch(appConfig.ip + '/web/agent/password-resets/sendotp', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(OtpValue)
    })

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      yield put({
        type: FETCH_FORGOTPASSWORD_SUCCESS_ACTION,
        payload: resJSON
      })

      // history.push({
      //   pathname: "/registerProfile"
      // });
      // history.go();
    }
  } catch (err) {
    if (err.ok === false) {
      if (err.status === 404) {
        yield toast.error('Mobile number not found', {
          autoClose: 3000
        })
      }
      yield put({
        type: FETCH_FORGOTPASSWORD_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

function* CheckOtp(otp) {


  const state = yield select()
  let mobNo = state.forgotReducer.forgtMobNo.mobileNo


  const checkValue = {
    pin: otp.otp.otpNo,
    mobileNo: mobNo
  }



  try {
    let res = yield fetch(
      appConfig.ip + '/web/agent/password-resets/checkpin/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(checkValue)
      }
    )

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      if (!resJSON.ok) {
        throw resJSON
      }
      yield put({
        type: FORGOT_CHECKOTP_SUCCESS_ACTION,
        payload: resJSON
      })

      yield toast.success('OTP verified successfully', {
        autoClose: 3000
      })
      // history.push({
      //   pathname: "/registerProfile"
      // });
      // history.go();
    }
  } catch (err) {
    if (err.ok === false) {
      yield toast.error('Invalid OTP', {
        autoClose: 3000
      })

      yield put({
        type: FORGOT_CHECKOTP_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

function* resendOtp() {
  const state = yield select()
  let mobNo = state.forgotReducer.forgtMobNo.mobileNo

  const resendValue = {
    mobileNo: mobNo
  }


  try {
    let res = yield fetch(
      appConfig.ip + '/web/agent/password-resets/resendotp',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(resendValue)
      }
    )

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      yield put({
        type: FORGOT_RESEND_OTP_SUCCESS_ACTION
      })
      yield toast.success('OTP has been resent successfully', {
        autoClose: 3000
      })
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FORGOT_RESEND_OTP_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

function* pswdChange(cred) {

  const state = yield select()
  let mobNo = state.forgotReducer.forgtMobNo.mobileNo
  let otp = state.forgotReducer.forgtOtp.otpNo


  const credValue = {
    password: cred.cred.newPassword,
    mobileNo: mobNo,
    pin: otp
  }


  try {
    let res = yield fetch(appConfig.ip + '/web/agent/password-resets/change', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credValue)
    })

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      if (!resJSON.ok) {
        throw resJSON
      }
      yield put({
        type: FORGOT_PSWRD_CHANGE_SUCCESS_ACTION,
        payload: resJSON
      })

      yield toast.success('Reset password successfully', {
        autoClose: 3000
      })

      yield put(push("/login"));

    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FORGOT_PSWRD_CHANGE_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

export function* forgotpasswordAction() {
  yield takeEvery(FORGOTPASSWORD_INIT_ACTION, forgotpassword)
  yield takeEvery(FORGOT_CHECKOTP_INIT_ACTION, CheckOtp)
  yield takeEvery(FORGOT_RESEND_OTP_INIT_ACTION, resendOtp)
  yield takeEvery(FORGOT_PSWRD_CHANGE_INIT_ACTION, pswdChange)
}
