import {
    FETCH_AGREEMENT_SUCCESS_ACTION
} from './action'

const initialState = {
    processing: false,
    error: false,
    autoDrvr: [],
    locPrediction: [],
    nearbyDrvrList: [],
    searchUser: []
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case '':
            return {
                ...state,
                processing: true
            }
        case FETCH_AGREEMENT_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                agreemntList: action.payload.rows,
                count: action.payload.count
            }
        case '':
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: true
            }
        default:
            return {
                ...state
            }
    }
}