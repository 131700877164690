/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link, NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

// import { styles } from './style.js'
import "./Navbar2.css";

// import './css/animate.css'
// import './css/bootstrap.min.css'

class Navbar3 extends Component {
  render() {
    return (
      <header class="ud-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="home">
                  <img src={require("../../assets/img/Asset 8.png")} alt="" />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="/navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon"> </span>
                  <span class="toggler-icon"> </span>
                  <span class="toggler-icon"> </span>
                </button>

                <div class="navbar-collapse">
                  <ul id="nav" class="navbar-nav mx-auto">
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item">
                      <a class="ud-menu-scroll" href="/home#home">
                        Home
                      </a>
                    </li>
                  </ul>
                </div>

                {/* <div class="navbar-btn d-none d-sm-inline-block">
                  <a href="login" class="ud-main-btn ud-login-btn">
                    Sign In
                  </a>
                  <a class="ud-main-btn ud-white-btn" href="signup">
                    Sign Up
                  </a>
                </div> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Navbar3;
