
import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
    PROFILE_INIT_ACTION,
    PROFILE_SUCCESS_ACTION,
    PROFILE_FAIL_ACTION,
    EDITPROFILE_INIT_ACTION,
    EDITPROFILE_SUCCESS_ACTION,
    EDITPROFILE_FAIL_ACTION
} from './actions'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

function* fetchProfile() {
    try {
        const userId = localStorage.getItem('userId')

        const token = localStorage.getItem('userdata')

        const res = yield fetch(`${appConfig.ip}/web/userProfs/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: PROFILE_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: PROFILE_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* editProfile(action) {
    const token = localStorage.getItem('userdata')
    const id = localStorage.getItem('userId')
    if (action.payload.value.fileImgVal) {
        const { file } = action.payload.value
        try {
            const data = new FormData()
            data.append('name', 'ProfPic')
            data.append('ProfPic', file, 'ProfPic')

            const res = yield fetch(`${appConfig.imgServerip}/addProfPic/`, {
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
                method: 'POST',
                body: data
            })
            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                const resJSON = yield res.json()
                const profileValue = {
                    state: action.payload.value.profileState,
                    district: action.payload.value.profileDistrict,
                    city: action.payload.value.profileCity,
                    pinCode: action.payload.value.profilePin,
                    idImglLink: resJSON.fileName,
                    // providerId: "open"
                }
                const resUser = yield fetch(`${appConfig.ip}/web/userProfs/`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(profileValue)
                })
                if (!resUser.ok) {
                    let errJSON1 = {}
                    try {
                        errJSON1 = yield resUser.json()

                    } catch { }
                    throw Object.assign(resUser, errJSON1)
                } else {
                    const resJSONUser = yield resUser.json()
                    const name = { name: action.payload.value.name, picture: resJSON.fileName }
                    const userName = yield fetch(`${appConfig.ip}/web/users/update`, {
                        method: 'PUT',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify(name)
                    })
                    if (!userName.ok) {
                        let errJSON2 = {}
                        try {
                            errJSON2 = yield userName.json()

                        } catch { }
                        throw Object.assign(userName, errJSON2)
                    }
                    else {
                        let userData = yield userName.json()
                        yield localStorage.removeItem('userDetail')
                        yield localStorage.setItem('userDetail', JSON.stringify(userData))
                        yield put({
                            type: EDITPROFILE_SUCCESS_ACTION,
                            payload: resJSONUser
                        })
                        yield put(push("/profile"))
                        yield put({ type: PROFILE_INIT_ACTION })

                        yield toast.success('Profile updated successfully', {
                            autoClose: 3000
                        })
                    }
                }
            }
        } catch (err) {

            if (err.ok === false) {
                yield put({ type: EDITPROFILE_FAIL_ACTION, error: err })
            } else {
            }
        }
    }
    else {
        try {
            const profileValuee = {
                state: action.payload.value.profileState,
                district: action.payload.value.profileDistrict,
                city: action.payload.value.profileCity,
                pinCode: action.payload.value.profilePin,
                // idImglLink: cred.value.value.file,
                // providerId: "open"
            }
            const resUsers = yield fetch(`${appConfig.ip}/web/userProfs/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(profileValuee)
            })
            if (!resUsers.ok) {
                let errJSON4 = {}
                try {
                    errJSON4 = yield resUsers.json()

                } catch { }
                throw Object.assign(resUsers, errJSON4)
            } else {
                const resJSONUsers = yield resUsers.json()
                const namee = { name: action.payload.value.name, picture: action.payload.value.file }
                const userNamee = yield fetch(`${appConfig.ip}/web/users/update`, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(namee)
                })
                if (!userNamee.ok) {
                    let errJSON5 = {}
                    try {
                        errJSON5 = yield userNamee.json()

                    } catch { }
                    throw Object.assign(userNamee, errJSON5)
                }
                else {
                    let userData = yield userNamee.json()
                    yield localStorage.removeItem('userDetail')
                    yield localStorage.setItem('userDetail', JSON.stringify(userData))
                    yield put({ type: PROFILE_INIT_ACTION })
                    yield put(push("/profile"))
                    yield put({
                        type: EDITPROFILE_SUCCESS_ACTION,
                        payload: resJSONUsers
                    })

                    yield toast.success('Profile updated successfully', {
                        autoClose: 3000
                    })
                }
            }
        }
        catch (err) {

        }
    }
}



export function* fleetProfile() {
    yield takeEvery(PROFILE_INIT_ACTION, fetchProfile)
    yield takeEvery(EDITPROFILE_INIT_ACTION, editProfile)
}