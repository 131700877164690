import * as React from 'react'

import DefaultLayout from '../../screens/DefaultLayout'
import { connect } from 'react-redux'

import { logOut } from '../LoginContainer/actions'
import { fetchProfile } from '../FleetProfileContainer/actions'

class DefaultLayoutContainer extends React.Component<Props, State> {

    render() {
        return <DefaultLayout logout={this.props.logout}
            history={this.props.history}
            profileData={this.props.profileData} />
    }
}

function bindAction(dispatch) {
    dispatch(fetchProfile())
    return {
        logout: () => {
            dispatch(logOut());
        }
    }
}

const mapStateToProps = state => ({
    profileData: state.fleetProfReducer.profileData
})

export default connect(
    mapStateToProps,
    bindAction
)(DefaultLayoutContainer)
