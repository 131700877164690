
// import React, { Component } from 'react'
// import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
// import Button from 'react-bootstrap/Button'
// import { Link, NavLink } from 'react-router-dom'
// import { Container } from 'react-bootstrap'

// import { styles } from './style.js'
// import './Navbar.css'

// import './css/animate.css'
// import './css/bootstrap.min.css'



// class Navbar2 extends Component {
//   render() {
//     return (
//       <header class="ud-header">
//         <div class="container">
//           <div class="row">
//             <div class="col-lg-12">
//               <nav class="navbar navbar-expand-lg">
//                 <a class="navbar-brand" href="home">
//                   <img src={require("../../assets/img/Asset 9.png")} alt="" />
//                 </a>
//                 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="/navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

//                   <span class="toggler-icon"> </span>
//                   <span class="toggler-icon"> </span>
//                   <span class="toggler-icon"> </span>
//                 </button>

//                 <div class="navbar-collapse">
//                   <ul id="nav" class="navbar-nav mx-auto">
//                     <li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#home">Home</a>
//                     </li><li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#why-us">Features</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#about">About</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#pricing">Pricing</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#faq">FAQ</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class="ud-menu-scroll" href="/home#contact">Contact</a>
//                     </li>

//                   </ul>

//                 </div>

//                 <div class="navbar-btn d-none d-sm-inline-block">
//                   <a href="login" class="ud-main-btn ud-login-btn">
//                     Sign In
//                   </a>
               
//                 </div>
//               </nav>
//             </div>

//           </div>
//         </div>
//       </header>
//     )
//   }
// }

// export default Navbar2

import React, { Component } from 'react'
import { Container, Image, Nav, Navbar, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

import './Navbar.css'
import { styles } from './style.js'

class Navbar2 extends Component {
  render() {
    return (
      <div className="homenavbarfirst">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="navbar "
          style={styles.nav}
          >
          <Container>
            <div className="logoNav">
              <Link to="/home" onClick={() => window.scrollTo(0, 0)}>
                <Image
                  className="homeimage"
                  src={require("../../assets/img/Asset 9.png")}
                />
              </Link>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto "></Nav>

              <Nav>
                <NavLink
                  exact
                  activeClassName="navbar__link--active"
                  className=" mainpagenavbar"
                  to="/home"
                  onClick={() => window.scrollTo(0, 0)}>
                  Home
                </NavLink>
                <li className="homenavbar">
                  <a className="homepagenavbar" href="/home#why-us">
                  Features
                  </a>
                </li>
                <li className="homenavbar">
                  <a className="homepagenavbar" href="home#about">
                    {' '}
                    About
                  </a>
                </li>
                {/*<li className="homenavbar">
                  <a className="homepagenavbar" href="home#pricing">
                    {' '}
                    Pricing
                  </a>
                </li>*/}
                <li className="homenavbar">
                  <a className="homepagenavbar" href="home#faq">
                    {' '}
                    FAQ
                  </a>
                </li>
                <li className="homenavbar">
                  <a className="homepagenavbar" href="home#contact">
                    {' '}
                    Contact
                  </a>
                </li>
                {/* ******************************************************************** */}

                <NavLink to="/login" onClick={() => window.scrollTo(0, 0)}>
                  {' '}
                  <Button
                    type="submit"
                    className="navbtn"
                    style={styles.navpgBtn}>
                    Sign In
                  </Button>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

export default Navbar2
