import { put, takeEvery } from 'redux-saga/effects'
import {
    SENDMSG_INIT_ACTION,
    SENDMSG_SUCCESS_ACTION,
    SENDMSG_FAIL_ACTION,
    SEND_ENQUIRY_INIT_ACTION,
    SEND_ENQUIRY_FAIL_ACTION,
    SEND_ENQUIRY_SUCCESS_ACTION
} from './action'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { appConfig } from '../../config'


function* sendMsg(action) {
    try {
        let res = yield fetch(
            appConfig.ip + '/web/sendEnquiry/sendMsg',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(action.payload)
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            yield toast.success('Message send successfully', {
                autoClose: 3000
            })
            yield put({
                type: SEND_ENQUIRY_SUCCESS_ACTION
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: SEND_ENQUIRY_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* sendEnquiry(action) {
    try {
        let res = yield fetch(
            appConfig.ip + '/web/sendEnquiry/fleetEnquiry',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(action.payload)
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            yield toast.success('Enquiry Message successfully', {
                autoClose: 3000
            })
            yield put({
                type: SENDMSG_SUCCESS_ACTION
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: SENDMSG_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

export function* home() {
    yield takeEvery(SENDMSG_INIT_ACTION, sendMsg)
    yield takeEvery(SEND_ENQUIRY_INIT_ACTION, sendEnquiry)
}