import React, { Component } from 'react';
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { createBrowserHistory } from 'history';

import Home from '../containers/HomeContainer';
import DefaultLayout from '../containers/DafaultLayoutContainer';
import Login from '../containers/LoginContainer';
import SignUp from '../containers/SignUpContainer';
import ForgotPassword from '../containers/ForgotPasswordContainer';
import RegisterOTP from '../containers/RegisterOTPContainer';
import ProfileUpdate from '../containers/ProfileUpdateContainer';
import Pricing from '../containers/PricingContainer';

var history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('userdata')
        ? <Component {...props} />
        : <Redirect to="/home" />}
  />;

function AppNavigator() {
  return (
    <Switch>
      <Route path="/home" name="Home" component={Home} />
      <Route path="/pricing" name="Pricing" component={Pricing} />
      <Route path="/login" name="Login" component={Login} />
      <Route path="/signUp" name="SignUp" component={SignUp} />
      <Route path="/forgotpassword" name="ForgotPassword" component={ForgotPassword} />
      <Route path="/registerOTP" name="RegisterOTP" component={RegisterOTP} />
      <Route path="/profileUpdate" name="ProfileUpdate" component={ProfileUpdate} />
      <Route path="/dasboard" name="Dashboard" component={DefaultLayout} />
      <Route path="/" name="Dashboard" component={DefaultLayout} />
    </Switch>
  );
}
export default AppNavigator;
