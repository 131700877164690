export const ADD_DRIVER_INIT_ACTION = "ADD_DRIVER_INIT_ACTION"
export const ADD_DRIVER_SUCCESS_ACTION = ADD_DRIVER_INIT_ACTION + '_SUCCESS'
export const ADD_DRIVER_FAIL_ACTION = ADD_DRIVER_INIT_ACTION + '_ERROR'

export const VERIFY_DRIVER_OTP_INIT_ACTION = "VERIFY_DRIVER_OTP_INIT_ACTION"
export const VERIFY_DRIVER_OTP_SUCCESS_ACTION = VERIFY_DRIVER_OTP_INIT_ACTION + '_SUCCESS'
export const VERIFY_DRIVER_OTP_FAIL_ACTION = VERIFY_DRIVER_OTP_INIT_ACTION + '_ERROR'

export const DRIVER_SEND_OTP_ACTION = "DRIVER_SEND_OTP_ACTION";
export const DRIVER_SEND_OTP_SUCCESS_ACTION = DRIVER_SEND_OTP_ACTION + '_SUCCESS'
export const DRIVER_SEND_OTP_FAIL_ACTION = DRIVER_SEND_OTP_ACTION + '_ERROR'

export const RESEND_OTP_INIT_ACTION = "ATTACH_DRIVER_RESEND_OTP_INIT_ACTION"
export const RESEND_OTP_SUCCESS_ACTION = RESEND_OTP_INIT_ACTION + '_SUCCESS'
export const RESEND_OTP_FAIL_ACTION = RESEND_OTP_INIT_ACTION + '_ERROR'

export const CHANGE_SCREEN_ACTION = "CHANGE_SCREEN_ACTION"

export function addDriver(value) {
    return {
        type: ADD_DRIVER_INIT_ACTION,
        payload: value
    }
}
export function verifyOtp(value) {
    return {
        type: VERIFY_DRIVER_OTP_INIT_ACTION,
        payload: value
    }
}
export function sendOtp(value) {

    return {
        type: DRIVER_SEND_OTP_ACTION,
        payload: value
    }
}
export function resendOtp(value) {
    return {
        type: RESEND_OTP_INIT_ACTION,
        payload: value
    }
}

export function changeScreen() {
    return {
        type: CHANGE_SCREEN_ACTION
    }
}