import { put, takeEvery } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
    LOGIN_INIT_ACTION,
    FETCH_LOGIN_FAIL_ACTION,
    FETCH_LOGIN_SUCCESS_ACTION,
    LOGOUT_ACTION,
    RESET_PSWD_INIT_ACTION,
    RESET_PSWD_FAIL_ACTION,
    RESET_PSWD_SUCCESS_ACTION
} from './actions'

import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

var btoa = require('Base64').btoa
var history = createBrowserHistory()

function* login(action) {
    try {
        const loginData = {
            mobileNo: action.payload.mobileNo,
            password: action.payload.password
        }
        const res = yield fetch(appConfig.ip + '/auth', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization:
                    'Basic ' +
                    btoa(loginData.mobileNo + '@agent.rydz.in' + ':' + loginData.password)
            }
        })
        if (!res.ok) {
            throw res
        } else {
            const resJSON = yield res.json()
            let userStatus = resJSON.user.status
            if (userStatus === 'active') {
                yield put({
                    type: FETCH_LOGIN_SUCCESS_ACTION,
                    payload: resJSON
                })
                localStorage.setItem('userId', resJSON.user.id)
                localStorage.setItem('userdata', resJSON.token)
                localStorage.setItem('userDetail', JSON.stringify(resJSON.user))
                if (resJSON.user.phnCnfrmd !== true) {
                    let otpRes = yield fetch(appConfig.ip + '/phoneVerify/sendotp', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + resJSON.token
                        }
                    })
                    yield put(push("/registerOTP"))
                    history.go()
                } else if (resJSON.user.ftLogin !== false) {
                    yield put(push("/profileUpdate"))
                    history.go()
                } else {
                    if (resJSON.user.role === 'agent') {
                        yield toast.error('You are not a fleet user', {
                            autoClose: 3000
                        })
                        // yield toast.success('Login successfully', {
                        //   autoClose: 3000
                        // })

                        // yield put(push("/openagentmain"))

                    } else if (resJSON.user.role === 'fleet') {
                        yield toast.success('Login successfully', {
                            autoClose: 3000
                        })
                        yield put(push("/dashboard"))

                    }
                }
            } else {
                yield toast.error('Your account is inactive. Contact your administrator to activate it', {
                    autoClose: 3000
                })
            }



        }
    } catch (err) {

        if (err.status === 401) {
            yield toast.error('Invalid credential', {
                autoClose: 3000
            })
        }
        yield put({
            type: FETCH_LOGIN_FAIL_ACTION,
            payload: err
        })
    }
}

function* logOut() {
    localStorage.removeItem('userdata')
    localStorage.removeItem('userId')
    localStorage.removeItem('userDetail')
    yield put(push("/login"))

}

function* resetPwsd(action) {
    try {
        let userData = JSON.parse(localStorage.getItem('userDetail'));
        const res = yield fetch(
            `${appConfig.ip}/users/${userData.id}/password`,
            {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization:
                        'Basic ' +
                        btoa(userData.email + ':' + action.payload.oldPswd)
                },
                body: JSON.stringify({ password: action.payload.newPswd })
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: RESET_PSWD_SUCCESS_ACTION,
                payload: resJSON
            })
            yield toast.success('Password reset successfully', {
                autoClose: 3000
            })
            yield put(push("/dashboard"))
        }
    } catch (err) {
        if (err.ok === false && err.status === 401) {
            yield toast.error('Incorrect old password', {
                autoClose: 3000
            })
            yield put({ type: RESET_PSWD_FAIL_ACTION, error: err })
        } else {
        }
    }
}


export function* loginActionWatcher() {
    yield takeEvery(LOGIN_INIT_ACTION, login)
    yield takeEvery(LOGOUT_ACTION, logOut)
    yield takeEvery(RESET_PSWD_INIT_ACTION, resetPwsd)
}
