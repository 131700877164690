import {
    FETCH_AUTO_DRIVER_INIT_ACTION,
    FETCH_AUTO_DRIVER_SUCCESS_ACTION,
    FETCH_AUTO_DRIVER_FAIL_ACTION,
    FETCH_NEARBY_AUTODRIVER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_SUCCESS_ACTION
} from './action'

const initialState = {
    processing: false,
    error: false,
    autoDrvr: [],
    locPrediction: [],
    nearbyDrvrList: [],
    searchUser: [],
    autoDrvrCount: 0,
    nearByDrvCount: 0
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case FETCH_AUTO_DRIVER_INIT_ACTION:
            return {
                ...state,
                processing: true
            }
        case FETCH_AUTO_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                autoDrvr: action.payload.rows,
                autoDrvrCount: action.payload.count
            }
        case FETCH_AUTO_DRIVER_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: true
            }
        case FETCH_NEARBY_AUTODRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                nearbyDrvrList: action.payload.rows,
                nearByDrvCount: action.payload.count,
                error: true
            }
        case SEARCH_AUTOUSER_SUCCESS_ACTION:
            return {
                ...state,
                searchUser: action.payload
            }
        default:
            return {
                ...state
            }
    }
}