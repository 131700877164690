import { call, all } from 'redux-saga/effects';

import { loginActionWatcher } from '../containers/LoginContainer/saga';
import { cabs } from '../containers/CabsContainer/saga';
import { attachDriver } from '../containers/AttachDriverContainer/saga';
import { fleetProfile } from '../containers/FleetProfileContainer/saga';
import { detachDriverAction } from '../containers/DetachDriverContainer/saga';
import { request } from '../containers/RequestContainer/saga';
import { auto } from '../containers/AutoContainer/saga';
import { dashboard } from '../containers/DashboardContainer/saga';
import { agreement } from '../containers/AgreementContainer/saga';
import { signUp } from '../containers/SignUpContainer/saga';
import { registerOTP } from '../containers/RegisterOTPContainer/saga'
import { forgotpasswordAction } from '../containers/ForgotPasswordContainer/saga'
import { home } from '../containers/HomeContainer/saga';

function* rootSaga() {
  yield all([
    call(loginActionWatcher),
    call(cabs),
    call(attachDriver),
    call(fleetProfile),
    call(detachDriverAction),
    call(request),
    call(auto),
    call(dashboard),
    call(agreement),
    call(signUp),
    call(registerOTP),
    call(forgotpasswordAction),
    call(home)
  ]);
}

export default rootSaga;
