
import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'
import {
    FETCH_DASHBOARD_INIT_ACTION,
    FETCH_DASHBOARD_FAIL_ACTION,
    FETCH_DASHBOARD_SUCCESS_ACTION
} from './action'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

function* fetchDashboard() {
    try {
        const token = localStorage.getItem('userdata')

        const res = yield fetch(
            `${appConfig.ip}/count/fleet`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_DASHBOARD_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.status === 401) {
            yield put(push("/home"))
        }
        else if (err.ok === false) {
            yield put({ type: FETCH_DASHBOARD_FAIL_ACTION, error: err })
        } else { }
    }
}

export function* dashboard() {
    yield takeEvery(FETCH_DASHBOARD_INIT_ACTION, fetchDashboard)
}