
import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'

import {
    FETCH_AUTO_DRIVER_INIT_ACTION,
    FETCH_AUTO_DRIVER_SUCCESS_ACTION,
    FETCH_AUTO_DRIVER_FAIL_ACTION,
    FETCH_NEARBY_AUTODRIVER_ACTION,
    FETCH_NEARBY_AUTODRIVER_FAIL_ACTION,
    FETCH_NEARBY_AUTODRIVER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_INIT_ACTION,
    SEARCH_AUTOUSER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_FAIL_ACTION,
    FETCH_AUTO_QUICKRQST_ACTION,
    FETCH_AUTO_QUICKRQST_FAIL_ACTION,
    FETCH_AUTO_QUICKRQST_SUCCESS_ACTION
} from './action'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'


function* fetchAutoDrivers(action) {
    try {
        const token = localStorage.getItem('userdata')
        const Autores = yield fetch(
            `${appConfig.ip}/web/geoLocs/getNearestAttachedDrivers?rideCat=auto&limit=10&page=${action.payload}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!Autores.ok) {
            let errJSON = {}
            try {
                errJSON = yield Autores.json()
            } catch { }
            throw Object.assign(Autores, errJSON)
        } else {
            const resJSON = yield Autores.json()
            yield put({
                type: FETCH_AUTO_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: FETCH_AUTO_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* fetchNearByDriver(action) {
    const placevalue = action.payload.place
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/geoLocs/getNearestAttachedDriversOfPlace?place=${placevalue}&&dist=5000&rideCat=auto&limit=1&page=${action.payload.page}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_NEARBY_AUTODRIVER_SUCCESS_ACTION,
                payload: resJSON
            })

        }
    } catch (err) {

        if (err.ok === false) {
            yield put({
                type: FETCH_NEARBY_AUTODRIVER_FAIL_ACTION,
                error: err
            })
        } else {
            // yield put({type: CHECK_INTERNET_ACTION, que: true, payload: action});
        }
    }
}

function* serachUser(action) {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/users/searchAttachedAutoDriver?ser=${action.payload}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: SEARCH_AUTOUSER_SUCCESS_ACTION,
                payload: resJSON
            })
        }

    } catch (err) {
        if (err.ok === false) {
            yield put({ type: SEARCH_AUTOUSER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* autoQuickRide(action) {

    const fleetautoValue = {
        rName: action.payload.name,
        rPhone: `+91${action.payload.mobileno}`,
        rideCat: action.payload.vType,
        accptdBy: action.payload.accptdBy,
        placeFrom: action.payload.placeFrom,
        placeTo: action.payload.placeTo,
        reqType: 'agentInit'
        // reqLoc: [11.5574563, 75.7605859],
        // reqFrom: [11.5574563, 75.7605859],
        // reqTo: [11.5704, 75.7536]
    }
    // console.log("=====fleetautoValue========", fleetautoValue)
    try {
        const token = localStorage.getItem('userdata')

        const res = yield fetch(`${appConfig.ip}/web/orders/agentRideRqst/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(fleetautoValue)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            // toast.success("Booking  successfully");
            yield toast.success('Successfully booked', {
                autoClose: 2000
            })
            const resJSON = yield res.json()
            yield put({
                type: FETCH_AUTO_QUICKRQST_SUCCESS_ACTION,
                payload: resJSON
            })
            yield put(push("/request"))
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: FETCH_AUTO_QUICKRQST_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}


export function* auto() {
    yield takeEvery(FETCH_AUTO_DRIVER_INIT_ACTION, fetchAutoDrivers)
    yield takeEvery(FETCH_NEARBY_AUTODRIVER_ACTION, fetchNearByDriver)
    yield takeEvery(SEARCH_AUTOUSER_INIT_ACTION, serachUser)
    yield takeEvery(FETCH_AUTO_QUICKRQST_ACTION, autoQuickRide)
}