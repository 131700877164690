export const LOGIN_INIT_ACTION = 'LOGIN_INIT'
export const FETCH_LOGIN_FAIL_ACTION = `${LOGIN_INIT_ACTION}_ERROR`
export const FETCH_LOGIN_SUCCESS_ACTION = `${LOGIN_INIT_ACTION}_SUCCESS`

export const LOGOUT_ACTION = 'LOGOUT'

export const RESET_PSWD_INIT_ACTION = 'RESET_PSWD_INIT_ACTION'
export const RESET_PSWD_FAIL_ACTION = `${RESET_PSWD_INIT_ACTION}_ERROR`
export const RESET_PSWD_SUCCESS_ACTION = `${RESET_PSWD_INIT_ACTION}_SUCCESS`


export function login(value) {
    return {
        type: LOGIN_INIT_ACTION,
        payload: value
    }
}

export function logOut() {
    return {
        type: LOGOUT_ACTION
    }
}

export function changePswd(value) {
    return {
        type: RESET_PSWD_INIT_ACTION,
        payload: value
    }
}