import * as React from 'react'
import { connect } from 'react-redux'
import ForgotPassword from '../../screens/ForgotPassword'
import { forgotpassword, CheckOtp, pswdChange, resendOtp, setInitialScreen } from './actions'
class ForgotPasswordSeaction extends React.Component<Props, State> {
  render() {
    return (
      <ForgotPassword
        forgotpassword={this.props.forgotpassword}
        CheckOtp={this.props.CheckOtp}
        resendOtp={this.props.resendOtp}
        pswdChange={this.props.pswdChange}
        screen={this.props.screen}
        setInitialScreen={this.props.setInitialScreen}
      >

      </ForgotPassword>
    )
  }
}
function bindAction(dispatch) {


  return {
    forgotpassword: value => {

      dispatch(forgotpassword(value))
    },
    CheckOtp: otpvalue => {

      dispatch(CheckOtp(otpvalue))
    },
    pswdChange: resetValue => {

      dispatch(pswdChange(resetValue))
    },
    resendOtp: () => {
      dispatch(resendOtp())
    },
    setInitialScreen: () => {
      dispatch(setInitialScreen())
    }
  }
}
function mapStateToProps(state) {
  return {
    screen: state.forgotReducer.screen,
    forgtMobNo: state.forgotReducer.forgtMobNo,
    forgtOtp: state.forgotReducer.forgtOtp
  }
}
export default connect(
  mapStateToProps,
  bindAction
)(ForgotPasswordSeaction)
