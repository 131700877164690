export const FORGOTPASSWORD_INIT_ACTION = 'FORGOTPASSWORD_INIT'
export const FETCH_FORGOTPASSWORD_FAIL_ACTION =
  FORGOTPASSWORD_INIT_ACTION + '_ERROR'
export const FETCH_FORGOTPASSWORD_SUCCESS_ACTION =
  FORGOTPASSWORD_INIT_ACTION + '_SUCCESS'

export const FORGOT_CHECKOTP_INIT_ACTION = 'FORGOT_CHECKOTP_INIT'
export const FORGOT_CHECKOTP_FAIL_ACTION =
  FORGOT_CHECKOTP_INIT_ACTION + '_ERROR'
export const FORGOT_CHECKOTP_SUCCESS_ACTION =
  FORGOT_CHECKOTP_INIT_ACTION + '_SUCCESS'

export const FORGOT_RESEND_OTP_INIT_ACTION = 'FORGOT_RESEND_OTP_INIT'
export const FORGOT_RESEND_OTP_FAIL_ACTION =
  FORGOT_RESEND_OTP_INIT_ACTION + '_ERROR'
export const FORGOT_RESEND_OTP_SUCCESS_ACTION =
  FORGOT_RESEND_OTP_INIT_ACTION + '_SUCCESS'

export const FORGOT_PSWRD_CHANGE_INIT_ACTION = 'FORGOT_PSWRD_CHANGE_INIT'
export const FORGOT_PSWRD_CHANGE_FAIL_ACTION =
  FORGOT_PSWRD_CHANGE_INIT_ACTION + '_ERROR'
export const FORGOT_PSWRD_CHANGE_SUCCESS_ACTION =
  FORGOT_PSWRD_CHANGE_INIT_ACTION + '_SUCCESS'

export const FORGOT_PSWRD_SCREENCHANGE_INIT_ACTION = 'FORGOT_PSWRD_SCREENCHANGE_ACTION'

export function forgotpassword(value) {

  return {
    type: FORGOTPASSWORD_INIT_ACTION,
    value: value
  }
}

export function CheckOtp(otp) {

  return {
    type: FORGOT_CHECKOTP_INIT_ACTION,
    otp: otp
  }
}

export function resendOtp() {
  return {
    type: FORGOT_RESEND_OTP_INIT_ACTION,
    meta: {
      retry: true
    }
  }
}

export function pswdChange(cred) {

  return {
    type: FORGOT_PSWRD_CHANGE_INIT_ACTION,
    cred: cred
  }
}

export function setInitialScreen() {

  return {
    type: FORGOT_PSWRD_SCREENCHANGE_INIT_ACTION,

  }
}
