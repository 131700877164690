import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  FETCH_ACTIVE_RQST_INIT_ACTION,
  FETCH_ACTIVE_RQST_FAIL_ACTION,
  FETCH_ACTIVE_RQST_SUCCESS_ACTION,
  FETCH_RQST_HISTORY_ACTION,
  FETCH_RQST_HISTORY_SUCCESS_ACTION,
  FETCH_RQST_HISTORY_FAIL_ACTION,
  RQST_VIEWPCKG_INIT_ACTION,
  RQST_VIEWPCKG_SUCCESS_ACTION,
  RQST_VIEWPCKG_FAIL_ACTION,
  CANCEL_INIT_ACTION,
  CANCEL_FAIL_ACTION,
  CANCEL_SUCCESS_ACTION,
} from "./actions";

import "react-toastify/dist/ReactToastify.css";
import { appConfig } from "../../config";

function* activeRequest(value) {
  try {
    const token = localStorage.getItem("userdata");
    let pageno = !value.value
      ? 1
      : value.value.page === undefined
        ? 1
        : value.value.page;
    let searchquery = !value.value
      ? ""
      : value.value === undefined
        ? ""
        : value.value.searchquery;
    let status = !value.value
      ? ""
      : value.value === undefined
        ? ""
        : value.value.status;



    const res = yield fetch(`${appConfig.ip}/web/orders/orderstatus/?status=${status}&search=${searchquery}&limit=10&page=` + pageno, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ACTIVE_RQST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ACTIVE_RQST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* requestHistory(value) {
  try {
    const token = localStorage.getItem("userdata");
    const userId = localStorage.getItem("userId");
    let pageno = !value.value
      ? 1
      : value.value.pages === undefined
        ? 1
        : value.value.pages;
    let searchkey = !value.value
      ? ""
      : value.value === undefined
        ? ""
        : value.value.searchkey;

    let status = !value.value
      ? ""
      : value.value === undefined
        ? ""
        : value.value.status;

    const res = yield fetch(
      `${appConfig.ip}/orders?userId=${userId}&status=${status}&search=${searchkey}&limit=10&page=` + pageno,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_RQST_HISTORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_RQST_HISTORY_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* rqstViewPckg(action) {
  const id = action.payload.item.dProfpkgId;
  if (action.payload.item.rideType !== "normal") {
    try {
      const token = localStorage.getItem("userdata");
      const res = yield fetch(`${appConfig.ip}/web/dProfPkg/` + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch { }
        throw Object.assign(res, errJSON);
      } else {
        const resJSON = yield res.json();

        yield put({
          type: RQST_VIEWPCKG_SUCCESS_ACTION,
          payload: resJSON,
        });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({ type: RQST_VIEWPCKG_FAIL_ACTION, error: err });
      } else {
      }
    }
  }
}

function* cancelRqst(action) {
  try {
    const orderId = action.payload.id;
    const data = {
      reqLoc: [11.564, 75.7564],
    };
    const token = localStorage.getItem("userdata");
    const res = yield fetch(
      `${appConfig.ip}/web/orders/riderCancel/${orderId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield toast.success("Successfully cancelled", {
        autoClose: 2000,
      });

      yield put({
        type: CANCEL_SUCCESS_ACTION,
        payload: resJSON.rows,
      });
      yield put({
        type: FETCH_ACTIVE_RQST_INIT_ACTION,
        value: {
          page: undefined,
          searchquery: '',
          status: "created,accepted,enroute,waiting,ontrip"
        }
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CANCEL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* request() {
  yield takeEvery(FETCH_ACTIVE_RQST_INIT_ACTION, activeRequest);
  yield takeEvery(FETCH_RQST_HISTORY_ACTION, requestHistory);
  yield takeEvery(RQST_VIEWPCKG_INIT_ACTION, rqstViewPckg);
  yield takeEvery(CANCEL_INIT_ACTION, cancelRqst);
}
