import {
  FORGOTPASSWORD_INIT_ACTION,
  FETCH_FORGOTPASSWORD_FAIL_ACTION,
  FETCH_FORGOTPASSWORD_SUCCESS_ACTION,
  FORGOT_CHECKOTP_INIT_ACTION,
  FORGOT_CHECKOTP_FAIL_ACTION,
  FORGOT_CHECKOTP_SUCCESS_ACTION,
  FORGOT_RESEND_OTP_INIT_ACTION,
  FORGOT_RESEND_OTP_FAIL_ACTION,
  FORGOT_RESEND_OTP_SUCCESS_ACTION,
  FORGOT_PSWRD_CHANGE_INIT_ACTION,
  FORGOT_PSWRD_CHANGE_FAIL_ACTION,
  FORGOT_PSWRD_CHANGE_SUCCESS_ACTION,
  FORGOT_PSWRD_SCREENCHANGE_INIT_ACTION
} from './actions'

const initialState = {
  processing: false,
  error: false,
  screen: 'ForgotNo'
}

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FORGOTPASSWORD_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
        forgtMobNo: action.value
      }
    case FETCH_FORGOTPASSWORD_FAIL_ACTION:

      return {
        ...state,
        processing: false,
        error: true
      }
    case FETCH_FORGOTPASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false,
        screen: 'ForgotOtp'
      }

    case FORGOT_CHECKOTP_INIT_ACTION:

      return {
        ...state,
        processing: true,
        error: false,
        forgtOtp: action.otp
      }
    case FORGOT_CHECKOTP_FAIL_ACTION:
      return {
        ...state,
        processing: false,
        error: true
      }
    case FORGOT_CHECKOTP_SUCCESS_ACTION:

      return {
        ...state,
        processing: false,
        error: false,
        //  otp: action.payload,
        screen: 'ForgotPswrdChng'
      }

    case FORGOT_RESEND_OTP_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false
      }

    case FORGOT_RESEND_OTP_FAIL_ACTION:
      return {
        ...state,
        processing: false,
        error: true
      }

    case FORGOT_RESEND_OTP_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false
      }

    case FORGOT_PSWRD_CHANGE_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false
      }
    case FORGOT_PSWRD_CHANGE_FAIL_ACTION:
      return {
        ...state,
        processing: false,
        error: true
      }
    case FORGOT_PSWRD_CHANGE_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false,
        screen: 'ForgotNo'
      }
    case FORGOT_PSWRD_SCREENCHANGE_INIT_ACTION:
      return {
        ...state,
        screen: 'ForgotNo'
      }

    default:
      return {
        ...state
      }
  }
}
