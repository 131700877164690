import * as React from 'react'

import Pricing from '../../screens/Pricing'
import { connect } from 'react-redux'
import { sendEnquiry } from '../HomeContainer/action'


class PricingContainer extends React.Component<Props, State> {
    render() {
        return <Pricing location={this.props.location}
            sendEnquiry={this.props.sendEnquiry} />
    }
}

function bindAction(dispatch) {
    return {
        sendEnquiry: (value) => {
            dispatch(sendEnquiry(value))
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(PricingContainer)
