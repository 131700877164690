
export const SENDMSG_INIT_ACTION = 'SENDMSG_INIT_ACTION'
export const SENDMSG_FAIL_ACTION =
    SENDMSG_INIT_ACTION + '_ERROR'
export const SENDMSG_SUCCESS_ACTION =
    SENDMSG_INIT_ACTION + '_SUCCESS'

export const SEND_ENQUIRY_INIT_ACTION = 'SEND_ENQUIRY_INIT_ACTION'
export const SEND_ENQUIRY_FAIL_ACTION =
    SENDMSG_INIT_ACTION + '_ERROR'
export const SEND_ENQUIRY_SUCCESS_ACTION =
    SENDMSG_INIT_ACTION + '_SUCCESS'

export function sendMsg(value) {
    return {
        type: SENDMSG_INIT_ACTION,
        payload: value
    }
}

export function sendEnquiry(value) {
    return {
        type: SEND_ENQUIRY_INIT_ACTION,
        payload: value
    }
}