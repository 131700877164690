import React from 'react';

//const Dashboard = React.lazy(() => import('./components/Dashboard'));
const DefaultLayout = React.lazy(() => import('../src/containers/DafaultLayoutContainer'));
const Login = React.lazy(() => import("./containers/LoginContainer"));
const Home = React.lazy(() => import("./containers/HomeContainer"));
const Dashboard = React.lazy(() => import("./containers/DashboardContainer"));
const Cabs = React.lazy(() => import("./containers/CabsContainer"));
const AttachDriver = React.lazy(() => import('./containers/AttachDriverContainer'))
const FleetProfile = React.lazy(() => import('./containers/FleetProfileContainer'))
const DetachDriver = React.lazy(() => import('./containers/DetachDriverContainer'))
const FleetEditProfile = React.lazy(() => import('./containers/FleetEditProfileContainer'))
const Request = React.lazy(() => import('./containers/RequestContainer'))
const Auto = React.lazy(() => import('./containers/AutoContainer'))
const ForgotPassword = React.lazy(() => import('./containers/ForgotPasswordContainer'))
// const SignUp = React.lazy(() => import('./containers/SignUpContainer'))
const Agreement = React.lazy(() => import('./containers/AgreementContainer'))
const ResetPswd = React.lazy(() => import('./containers/ResetPswdContainer'))
const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   name: 'Home',
  // },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    //name: 'Login',
    component: ForgotPassword,
  },
  // {
  //   path: '/signUp',
  //   //name: 'Login',
  //   component: SignUp,
  // },
  {
    path: '/dashboard',
    // name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/cabs',
    // name: 'Cabs',
    component: Cabs,
  },
  {
    path: '/attachDriver',
    //  name: 'Cabs',
    component: AttachDriver,
  },
  {
    path: '/profile',
    //  name: 'Cabs',
    component: FleetProfile,
  },
  {
    path: '/detachDriver',
    //  name: 'Cabs',
    component: DetachDriver,
  },
  {
    path: '/fleetEditProfile',
    //  name: 'Cabs',
    component: FleetEditProfile,
  },
  {
    path: '/resetPswd',
    //  name: 'Cabs',
    component: ResetPswd,
  },
  {
    path: '/request',
    //  name: 'Cabs',
    component: Request,
  },
  {
    path: '/auto',
    //  name: 'Cabs',
    component: Auto,
  },
  {
    path: '/agreement',
    //  name: 'Cabs',
    component: Agreement,
  },

];

export default routes;
