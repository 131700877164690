export const ATTACHED_DRIVER_INIT_ACTION = "ATTACHED_DRIVER_INIT_ACTION"
export const ATTACHED_DRIVER_SUCCESS_ACTION = ATTACHED_DRIVER_INIT_ACTION + '_SUCCESS'
export const ATTACHED_DRIVER_FAIL_ACTION = ATTACHED_DRIVER_INIT_ACTION + '_ERROR'

export const DETACH_DRIVER_INIT_ACTION = "DETACH_DRIVER_INIT_ACTION"
export const DETACH_DRIVER_SUCCESS_ACTION = DETACH_DRIVER_INIT_ACTION + '_SUCCESS'
export const DETACH_DRIVER_FAIL_ACTION = DETACH_DRIVER_INIT_ACTION + '_ERROR'

export function fetchAttachedDriver(value) {
    return {
        type: ATTACHED_DRIVER_INIT_ACTION,
        value:value
    }
}

export function detachDriver(value) {
    return {
        type: DETACH_DRIVER_INIT_ACTION,
        payload: value
    }
}
export function DetachdriverSlectedPage(value) {
    return {
      type: ATTACHED_DRIVER_INIT_ACTION,
      value: value
    };
  }