import React from "react";
import { Component } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./style.css";
// import { styles } from './style.js'
import Navbar2 from "../../components/Navbar";
import Footer from "../../components/Footer";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import "./css/animate.css";
import "./css/bootstrap.min.css";
import "./css/lineicons.css";
import "./css/ud-styles.css";

import heros from "./images/hero/heros.jpg";
import ABOUT from "./images/hero/ABOUT.png";


import { Accordion, Card } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";


const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)
const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(val => val.length > 0 && (valid = false))
  return valid
}
const validphoneRegex = RegExp(/^\d{10}$/)

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "rydzfleet",
      value: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
      },
      errors: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
      }
    };
    this.onChange = this.onChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  onChange(values) {

    const { value, name } = values.target
    this.setState({
      value: { ...this.state.value, [name]: value }

      // showError: false
    })
    let errors = this.state.errors
    switch (name) {
      case 'name':
        errors.name = value.length < 3 ? 'Name must be 3 characters long' : ''
        break
      case 'mobileNo':
        errors.mobileNo = validphoneRegex.test(value)
          ? ''
          : 'Mobile number is not valid'
        break
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid'
        break
      case 'message':
        errors.message = value.length < 3 ? 'Message is required' : ''
        break
      default:
        break
    }
    this.setState({ errors, [name]: value })
  }

  submit(event) {
    event.preventDefault()
    if (
      validateForm(this.state.errors) &&
      this.state.value.name !== "" &&
      this.state.value.mobileNo !== "" &&
      this.state.value.email !== "" &&
      this.state.value.message !== ""
    ) {
      this.setState({
        value: {
          name: '',
          mobileNo: '',
          email: '',
          message: '',
        }
      })
      let data = this.state.value
      this.props.sendMsg(data)
      this.setState({ name: undefined })
      this.setState({ mobileNo: undefined })
      this.setState({ email: undefined })
      this.setState({ message: undefined })
    } else {
      toast.error('Please enter valid datas', {
        autoClose: 2000
      })
    }
  }

  render() {
    let value = this.state.value
    const { errors } = this.state
    return (
      <div>
        <Navbar2 />
        {/* <div className="fleetpage" >
                    <Container>
                        <div className="fleet_page_main">
                            <Col md={6} lg={6}>
                                <h3 className="Fleet_page_head"> Fleet Management </h3>
                                <p className="Fleet_page_para">
                                    If your organization own or operate or rent or lease lots of
                                    vehicles, Rydz Fleet Management program helps you monitor and
                                    manage trip expenses in real time. You can also schedule the trips
                                    and reduce idle time of your fleets without any investment on
                                    costly vehicle tracking hardware or software systems. You just pay
                                    subscription fee according to number of fleets and users you have.
                                </p>
                            </Col>
                            <div className="Fleet_page_buttondiv">
                                <div className="Fleet_Page_button">
                                    <Link
                                        to={{
                                            pathname: `/login`,
                                            state: 'fleet'
                                        }} onClick={() => window.scrollTo(0, 0)}>
                                        <Button
                                            className="Fleet_Page_btn"
                                            size="lg"
                                        >
                                            Sign-Up Fleet Management
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </Container>
                </div> */}

        {/************* * Hero Start************/}
        <section class="ud-hero" id="home">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                  <h1 class="ud-hero-title">
                    Easy, reliable, accurate fleet management solution
                  </h1>
                  <p class="ud-hero-desc">
                    Our complete fleet management solutions, unmatched
                    attention, and ongoing collaboration ensure that clients
                    from a variety of industries achieve extraordinary value for
                    their businesses.
                  </p>
                  <ul class="ud-hero-buttons">
                    <li>
                      <a
                        href="#about"
                        rel="nofollow noopener"
                        class="ud-main-btn ud-white-btn"
                      >
                        Get Started
                      </a>
                    </li>
                    {/* <li>
                                            <a href="https://www.rydz.in" rel="nofollow noopener" target="_blank" class="ud-main-btn ud-link-btn">
                                                Learn More about rydz <i class="lni lni-arrow-right"></i>
                                            </a>
                                        </li> */}
                  </ul>
                </div>
                <div
                  class="ud-hero-brands-wrapper wow fadeInUp"
                  data-wow-delay=".3s"
                >

                  <a href="#about">
                    <img
                      src={require("./images/brands/Asset 9.png")}
                      alt="brand"
                    />
                  </a>
                  <a href="#about">
                    <img
                      href="#about"
                      src={require("./images/brands/Asset 8.png")}
                      alt="brand"
                    />
                  </a>
                </div>
                <div class="ud-hero-image wow fadeInUp" data-wow-delay=".25s">

                  <img
                    src={heros}
                    alt="hero-image"
                  />
                  <img
                    src={require("./images/hero/dotted-shape.svg")}
                    alt="shape"
                    class="shape shape-1"
                  />
                  <img
                    src={require("./images/hero/dotted-shape.svg")}
                    alt="shape"
                    class="shape shape-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/***  ====== Hero End ====== -->*/}

        {/** ====== Features Start ====== 
                <section id="features" class="ud-features">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title">
                                    <span>Features</span>
                                    <h2>RydzFleet introduce</h2>
                                    <span>RydzFleet</span> & <span>RydzServices</span>
                                    <p>
                                        The easy, all-in-oneplatform for fleets
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-timer"></i>
                                    </div>
                                    <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Save time</h3>
                                        <p class="ud-feature-desc">
                                        Say goodbye to double-bookings, painful spreadsheet and time-consuming phone calls.
                                        </p>
                                        {/* <!-- <a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".15s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-reload"></i>
                                    </div>
                                    <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Work Orders</h3>
                                        <p class="ud-feature-desc">
                                        Plan, schedule and carry out detailed fleet maintenance work orders, 
                                        tasks and costs
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".2s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-laptop-phone"></i>
                                    </div>
                                    <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">It’s made for your business</h3>
                                        <p class="ud-feature-desc">
                                        RydzFleet is designed for fleet management businesses looking to make 
                                        life easier and increase profitability.
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-taxi"></i>
                                    </div>
                                    <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Increase online bookings</h3>
                                        <p class="ud-feature-desc">
                                        Inventory management software to increase online bookings.
                                        </p>
                                        {/* <!---<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-website"></i>
                                    </div>
                                         <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Mini but mighty</h3>
                                        <p class="ud-feature-desc">
                                        When building RydzFleet we focused on what it takes to make a small fleet profitable. 
                                        Our online booking system was the brainchild, it ensures you capture every opportunity.
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-revenue"></i>
                                    </div>
                                         <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">High volume all-rounder</h3>
                                        <p class="ud-feature-desc">
                                        RydzFleet provides all of the features needed to ensure your 
                                        business runs smoothly when operating at high volume.
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-users"></i>
                                    </div>
                                         <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Multi-venue tycoons</h3>
                                        <p class="ud-feature-desc">
                                        RydzFleet provides features that enable a smooth user experience and which provide real-time data.
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
                                    <div class="ud-feature-icon">
                                        <i class="lni lni-handshake"></i>
                                    </div>
                                         <div class="ud-feature-content">
                                        <h3 class="ud-feature-title">Available locally</h3>
                                        <p class="ud-feature-desc">
                                        You can run the fleet locally with the help of the rydzservice.
                                        </p>
                                        {/* <!--<a href="javascript:void(0)" class="ud-feature-link">
                                            Learn More
                                        </a>--> 
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                {/**<!-- ====== Features End ====== -->*/}
        {/**<!-- ======= Why Us Section ======= -->*/}
        <section id="why-us" class="why-us">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-5" data-aos="fade-up">
                <div class="content">
                  <h3>Why Choose RydzFleet?</h3>
                  <p>
                    Simplicity makes it easy to run a business and have your
                    work published rapidly. Nothing should stand in the way of
                    you having your fleet management system up and running and
                    expanding your business. Rydzfleet is designed to facilitate
                    this.
                  </p>
                  {/*<div class="text-center">
                <a href="#" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
                </div>*/}
                </div>
              </div>
              <div class="col-xl-8 col-lg-7 d-flex">
                <div class="icon-boxes d-flex flex-column justify-content-center">
                  <div class="row">
                    <div
                      class="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div class="icon-box mt-4 mt-xl-0">
                        <i class="bx bx-receipt"></i>
                        <h4>Corporate Partner</h4>
                        <p>
                          Serve taxi booking needs of your
                          organization/customers through a single platform.
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div class="icon-box mt-4 mt-xl-0">
                        <i class="bx bx-cube-alt"></i>
                        <h4>Service Partner</h4>
                        <p>
                          Take your taxi service to our open taxi platform and
                          make your business more competitive.
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div class="icon-box mt-4 mt-xl-0">
                        <i class="bx bx-images"></i>
                        <h4>Customers</h4>
                        <p>Faster by connecting the drivers.</p>
                        <p> Simple Concept,Big Savings.</p>
                        <p>Customers package travel available.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- End Why Us Section -->*/}

        {/* <!-- ====== About Start ====== -->*/}
        <section id="about" class="ud-about">
          <div class="container">
            <div class="ud-about-wrapper wow fadeInUp" data-wow-delay=".2s">
              <div class="ud-about-content-wrapper">
                <div class="ud-about-content">
                  <span class="tag">About our Services</span>
                  <h2>RydzFleet Corporate for Fleet Owners</h2>
                  <p>
                    If you are a promoter or officer responsible for transport
                    department of your company or operating a tour & travel
                    company managing multiple transportation vendors to serve
                    your large employees and/or customers, RydzFleet is suitable
                    for you. You can schedule or book in real-time vehicles from
                    your multiple vendors which reduce idle time/expenses
                    related to trip scheduling.
                  </p>

                  <button
                    href="pricing"
                    rel="nofollow noopener"
                    class="ud-main-btn ud-link-btn"
                    onClick={() => { this.props.history.push({ pathname: `/pricing`, state: { role: 'fleet' }, }) }}
                  >
                    SignUp for RydzFleet
                  </button>

                  <h2>RydzService Partner for aggregator</h2>

                  <p>
                    Rydzservice is merely a business partner for Rydzfleet. It
                    works for bringing your taxi service to our open taxi
                    platform and increasing the competitiveness of your
                    business. It's merely a matter of working together with an
                    open schedule on local services and assisting in the
                    formation of taxi services.
                  </p>

                  <button
                    href="pricing"
                    rel="nofollow noopener"
                    class="ud-main-btn ud-link-btn"
                    onClick={() => { this.props.history.push({ pathname: `/pricing`, state: { role: 'service' }, }) }}
                  >
                    SignUp for RydzService
                  </button>
                  {/*} <!--<a href="javascript:void(0)" class="ud-main-btn">Learn More</a>-->*/}
                </div>
              </div>
              <div class="ud-about-image">
                <img
                  src={ABOUT}
                  alt="aboutImage"
                />
              </div>
            </div>
          </div>
        </section>
        {/**<!-- ====== About End ====== -->*/}

        {/*<!-- ====== Pricing Start ====== -->*/}
        {/*<section id="pricing" class="ud-pricing">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">

                <h2 class="title">Plans to fit your fleet operation</h2>
                <p class="text">Simplicity makes it easy to run a business and have your work published rapidly.
                  Nothing should stand in the way of you having your fleet management system up and running and expanding your business.
                  Rydzfleet is designed to facilitate this..
                </p>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                  <div class="pricingTable-header">

                  </div>
                  <div class="pricing-plans">
                    <span class="price-value"><span>Rydz Service</span></span>
                    <span class="subtitle">Available Plans</span>
                  </div>
                  <div class="pricingContent">
                    <ul>
                      <li><b>Rs.0</b> Demo Package</li>
                      <li><b>Rs.499</b>Monthly Package</li>
                      <li><b>Rs.999</b> 3 Month Package</li>
                      <li><b>Rs.1999</b> Yearly Package</li>

                    </ul>
                  </div>

                  <div class="pricingTable-sign-up">
                    <button href="pricing" class="btn btn-block btn-default" onClick={() => { this.props.history.push({ pathname: `/pricing`, state: { role: 'service' }, }) }}
                    > Select Plan</button>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                  <div class="pricingTable-header">

                  </div>
                  <div class="pricing-plans">
                    <span class="price-value"><span>Rydz Fleet</span></span>
                    <span class="subtitle">Available Plans</span>
                  </div>
                  <div class="pricingContent">
                    <ul>
                      <li><b>Rs.0</b> Demo Package</li>
                      <li><b>Rs.499</b>Monthly Package</li>
                      <li><b>Rs.999</b> 3 Month Package</li>
                      <li><b>Rs.1999</b> Yearly Package</li>

                    </ul>
                  </div>
                  <div class="pricingTable-sign-up">
                    <button href="pricing" class="btn btn-block btn-default" onClick={() => { this.props.history.push({ pathname: `/pricing`, state: { role: 'fleet' }, }) }}
                    >Select Plan</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>*/}
        {/*<!-- ====== FAQ End ====== -->*/}

        {/*<!-- ====== Testimonials Start ====== -->*
                <section id="testimonials" class="ud-testimonials">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title mx-auto text-center">
                                    <span>Testimonials</span>
                                    <h2>What our Customers Says</h2>
                                    <p>
                                        There are many variations of passages of Lorem Ipsum available
                                        but the majority have suffered alteration in some form.
                                    </p>
                                </div>
                            </div>
                        </div>

        {/*<!-- ====== FAQ Start ====== -->
        <section id="faq" class="ud-faq">
          <div class="shape">
            <img src={require("./images/faq/shape.svg")} alt="shape" />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ud-section-title text-center mx-auto">
                  <span>FAQ</span>
                  <h2>Frequently Asked Questions</h2>
                  <p>Find the answers to all your RydzFleet questions.</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>What is RydzFleet?</span>
                    </button>
                    <div id="collapseOne" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        The Rydz Fleet Management application helps you track
                        and control trip expenses in real time if your company
                        owns, operates, rents, or leases a large number of cars.
                        Without investing in expensive vehicle tracking hardware
                        or software solutions, you may also organise trips and
                        eliminate idle time in your fleets. You simply pay a
                        monthly charge based on the amount of fleets and users
                        you have.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>What is RydzService?</span>
                    </button>
                    <div id="collapseTwo" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        Rydzservice is merely a business partner for Rydzfleet.
                        It works for bringing your taxi service to our open taxi
                        platform and increasing the competitiveness of your
                        business. It's merely a matter of working together with
                        an open schedule on local services and assisting in the
                        formation of taxi services.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>
                        What is the main difference between RydzFleet and
                        RydzService?
                      </span>
                    </button>
                    <div id="collapseThree" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        Rydzfleet is a one-stop-shop for all of your needs. The
                        corporate partner is exclusively used by Rydzfleet.
                        Rydzservices will manage the open drivers, attach the
                        fleet, and assign the operations with the help of a
                        local service partner.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>Can I change my plan at any time?</span>
                    </button>
                    <div id="collapseFour" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        Yes. You can change your Fleetio plan at any time by
                        visiting the Settings area.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>Can I get help setting up my account?</span>
                    </button>
                    <div id="collapseFive" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        For those who want to expedite the RydzFleet setup and
                        onboarding process, we offer Onboarding & Implementation
                        Services delivered by our Customer Success Team to get
                        your team using RydzFleet to its full extent in no time.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
                  <div class="accordion">
                    <button
                      class="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                    >
                      <span class="icon flex-shrink-0">
                        <i class="lni lni-chevron-down"></i>
                      </span>
                      <span>How secure is my data?</span>
                    </button>
                    <div id="collapseSix" class="accordion-collapse collapse">
                      <div class="ud-faq-body">
                        We take data seriously at RydzFleet and ensure our
                        system is always up to date with the latest security
                        practices. Your data and files are transmitted using SSL
                        encryption both to and from your web browser.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- ====== FAQ End ====== -->*/}
        {/*======new faq======*/}
        <section
          id="faq"
          class="accordion-section clearfix mt-3 about-section received-section pt-150"
          aria-label="Question Accordions"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ud-section-title text-center mx-auto">
                  <span>FAQ</span>
                  <h2>Frequently Asked Questions</h2>
                  <p>Find the answers to all your RydzFleet questions.</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 ">
                <div className="faqsection">
                  <Tabs
                    activeKey={this.state.key}
                    onSelect={(k) => {
                      this.setState({ ...this.state, key: k });
                    }}
                  >
                    <Tab
                      className="orderTab"
                      eventKey="rydzfleet"
                      title="RydzFleet"
                    >
                      <div className="Accordionsection">
                        <Accordion >
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="Accordionsectionhd"
                              >
                                1. What is RydzFleet?
                              </Accordion.Toggle>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="Accordionsectionicon"
                              >
                                <i class="fa fa-chevron-down"></i>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div>
                                  <p>
                                    The Rydz Fleet Management application helps
                                    you track and control trip expenses in real
                                    time if your company owns, operates, rents, or
                                    leases a large number of cars. Without
                                    investing in expensive vehicle tracking
                                    hardware or software solutions, you may also
                                    organise trips and eliminate idle time in your
                                    fleets. You simply pay a monthly charge based
                                    on the amount of fleets and users you have.
                                  </p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>

                          </Card>


                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                className="Accordionsectionhd"
                              >
                                2. What is RydzService?
                              </Accordion.Toggle>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                className="Accordionsectionicon"
                              >
                                <i class="fa fa-chevron-down"></i>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div>
                                  <p>
                                    The Rydz Fleet Management application helps
                                    you track and control trip expenses in real
                                    time if your company owns, operates, rents, or
                                    leases a large number of cars. Without
                                    investing in expensive vehicle tracking
                                    hardware or software solutions, you may also
                                    organise trips and eliminate idle time in your
                                    fleets. You simply pay a monthly charge based
                                    on the amount of fleets and users you have.
                                  </p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>

                          </Card>


                        </Accordion>
                      </div>
                    </Tab>
                    <Tab
                      className="orderTab"
                      eventKey="rydzservices"
                      title="RydzServices"
                    >
                      <div className="Accordionsection">
                        <Accordion >
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="Accordionsectionhd"
                              >
                                1. What is RydzService?
                              </Accordion.Toggle>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="Accordionsectionicon"
                              >
                                <i class="fa fa-chevron-down"></i>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div>
                                  <p>
                                    The Rydz Fleet Management application helps
                                    you track and control trip expenses in real
                                    time if your company owns, operates, rents, or
                                    leases a large number of cars. Without
                                    investing in expensive vehicle tracking
                                    hardware or software solutions, you may also
                                    organise trips and eliminate idle time in your
                                    fleets. You simply pay a monthly charge based
                                    on the amount of fleets and users you have.
                                  </p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>

                          </Card>


                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                className="Accordionsectionhd"
                              >
                                2. What is RydzFleet?
                              </Accordion.Toggle>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                className="Accordionsectionicon"
                              >
                                <i class="fa fa-chevron-down"></i>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div>
                                  <p>
                                    The Rydz Fleet Management application helps
                                    you track and control trip expenses in real
                                    time if your company owns, operates, rents, or
                                    leases a large number of cars. Without
                                    investing in expensive vehicle tracking
                                    hardware or software solutions, you may also
                                    organise trips and eliminate idle time in your
                                    fleets. You simply pay a monthly charge based
                                    on the amount of fleets and users you have.
                                  </p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>

                          </Card>


                        </Accordion>
                      </div>
                    </Tab>
                  </Tabs>
                  {/* <nav>
                  <div
                    class="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="/home#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <strong>RydzFleet</strong>
                    </a>

                    

                    <a
                      class="nav-item nav-link"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="/home#nav-service"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      <strong>RydzServices</strong>
                    </a>
                  </div>
                </nav> */}
                </div>
              </div>
            </div>
            {/* <div class=" py-3 px-3 px-sm-0" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          1. What is RydzFleet?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer1"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          The Rydz Fleet Management application helps you track
                          and control trip expenses in real time if your company
                          owns, operates, rents, or leases a large number of
                          cars. Without investing in expensive vehicle tracking
                          hardware or software solutions, you may also organise
                          trips and eliminate idle time in your fleets. You
                          simply pay a monthly charge based on the amount of
                          fleets and users you have.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer2"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          2. What is RydzService?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer2"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          Rydzservice is merely a business partner for
                          Rydzfleet. It works for bringing your taxi service to
                          our open taxi platform and increasing the
                          competitiveness of your business. It's merely a matter
                          of working together with an open schedule on local
                          services and assisting in the formation of taxi
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer3"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          3. what are the benefits to choose the fleet?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer3"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          Once the signup is completed, you can subscribe the
                          broadcasted schedules from the home page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer4"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          4. Where can i find the provided services?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer4"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          You can find the provided services on the homepage.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer5"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          5. Can i change my plan at any time?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer5"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          We respect the customer’s choices, you can cancel any
                          on-demand orders before the scheduled time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer6"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          6. Can I get help setting up my account?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer6"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          Signing up is easy. Simply select signup option and
                          fill out the Customers Details form. We do ask you
                          please fill in your Name,contact number and location
                          details. Once the signup completed , the available
                          routes will be displayed on the home page which is
                          based on your nearby location. You can subscribe the
                          provided schedules from the list.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="info panel panel-default">
                    <div
                      class="panel-heading p-3 mb-3"
                      role="tab"
                      id="heading1"
                    >
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          role="button"
                          title=""
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#Customer7"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          7. How do i report a problem or concern?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="Customer7"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="heading1"
                    >
                      <div class="panel-body px-3 mb-4">
                        <p>
                          Yes, you can report a problem or concerns through
                          easyBin App. We provide 24/7 customer support.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-service"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div
                    class="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div class="info panel panel-default">
                      <div
                        class="panel-heading p-3 mb-3"
                        role="tab"
                        id="heading1"
                      >
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            title=""
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapse1"
                            aria-expanded="true"
                            aria-controls="collapse1"
                          >
                            1. What is RydzService?
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapse1"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="heading1"
                      >
                        <div class="panel-body px-3 mb-4">
                          <p>
                            Any waste management service companies or
                            contractors, scrap dealers and wholesalers, material
                            recycling firms and any other firms who are need
                            waste or scrap materials can enrol with easybin
                            platform as an “Agency”.
                          </p>
                        </div>
                      </div>
                      <div class="info panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading1"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse2"
                              aria-expanded="true"
                              aria-controls="collapse2"
                            >
                              2. How to signup as RydzFleet or Is there any
                              signup for an RydzFleet?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse2"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading1"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              There is no signup feature for agencies either in
                              easybin app or portal. If you want to enrol with
                              easybin platform for your collection need, please
                              contact us through our website www.easybin.in or
                              email us info@easybin.in. Our admin team will
                              create Agency signup for you and provide you
                              credentials for easybin agency portal.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="info panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading1"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse3"
                              aria-expanded="true"
                              aria-controls="collapse3"
                            >
                              3. What are the benefits of partnering as an
                              RydzService?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse3"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading1"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              Easybin platform focus on enabling technology for
                              waste and scrap recovery service companies. The
                              platform helps your existing collection process by
                              digitalizing the process and workflow. The
                              platform help you to scale your operation to more
                              areas with little investment of money and
                              resources. You can hire field agents easybin and
                              plan regular and on-demand orders. You can design
                              collection routes and broadcast to customers in
                              your targeted area through this platform very
                              easily. The biggest advantage is sorted and
                              segregated materials from the source significantly
                              reduce your collection and recovery of reusable
                              material cost. The platform also reduces effort to
                              prepare documentation reports required for local
                              compliances.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading2"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse4"
                              aria-expanded="true"
                              aria-controls="collapse4"
                            >
                              4. What are the responsibilities for an
                              RydzService?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse4"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading2"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              1. Agency needs to attach pick up agents who are
                              registered to the system in-order to work 2.
                              Accept the on-demand orders requested by the
                              customers 3. Create a regular schedule of picking
                              up a particular type of waste and allocating that
                              to pick up agents 4. Waste management fee for
                              sub-categories also need to be set by the Agency{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading3"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse5"
                              aria-expanded="true"
                              aria-controls="collapse5"
                            >
                              5. Is my personal information kept private?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse5"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading3"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              All required information is used for internal
                              purposes only and it will not be shared. Please
                              refer our privacy policy for more information.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading3"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse6"
                              aria-expanded="true"
                              aria-controls="collapse6"
                            >
                              6. Can my services operate in multiple areas?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse6"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading3"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              Yes, you can operate in multiple areas and
                              schedule regular pick-ups
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div
                          class="panel-heading p-3 mb-3"
                          role="tab"
                          id="heading3"
                        >
                          <h4 class="panel-title">
                            <a
                              class="collapsed"
                              role="button"
                              title=""
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse7"
                              aria-expanded="true"
                              aria-controls="collapse7"
                            >
                              7. Should I communicate directly with the
                              customers?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse7"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="heading3"
                        >
                          <div class="panel-body px-3 mb-4">
                            <p>
                              Agencies shall approach customers via pick-up
                              agents for regular orders. Agencies shall directly
                              communicate with enterprise customers and
                              on-demand orders for proper allocation of the
                              field agents.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div class="ud-form-group mb-0">
                <button type="submit" href="" class="ud-main-btn1">
                  Find more FAQs
                </button>
              </div>
            </div> */}
          </div>
        </section>

        {/*<!-- ====== Contact Start ====== -->*/}
        {/*</div>/ <section id="contact" class="ud-contact">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xl-8 col-lg-7">
                                <div class="ud-contact-content-wrapper">
                                    <div class="ud-contact-title">
                                        <span>CONTACT US</span>
                                        <h2>
                                            <br />
                                            Want to know more about the RydzFleet Management platform?
                                        </h2>
                                    </div>
                                    <div class="ud-contact-info-wrapper">
                                        <div class="ud-single-info">
                                            <div class="ud-info-icon">
                                                <i class="lni lni-map-marker"></i>
                                            </div>
                                            <div class="ud-info-meta">
                                                <h5>Our Location</h5>
                                                <p>Frugal Scientific Pvt Ltd</p>
                                                <p>#677, 1st Floor, 13th Cross Road,
                                                27th Main Rd, 1st Sector,
                                                HSR Layout, Bengaluru, Karnataka 560102, India
       </p>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div
                  class="ud-single-testimonial wow fadeInUp"
                  data-wow-delay=".1s"
                >
                  <div class="ud-testimonial-ratings">
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                  </div>
                  <div class="ud-testimonial-content">
                    <p>
                      “Our members are so impressed. It's intuitive. It's clean.
                      It's distraction free. If you're building a community.
                    </p>
                  </div>
                  <div class="ud-testimonial-info">
                    <div class="ud-testimonial-image">
                      <img
                        src={require("./images/testimonials/author-01.png")}
                        alt="author"
                      />
                    </div>
                    <div class="ud-testimonial-meta">
                      <h4>Sabo Masties</h4>
                      <p>Founder @UIdeck</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div
                  class="ud-single-testimonial wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <div class="ud-testimonial-ratings">
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                  </div>
                  <div class="ud-testimonial-content">
                    <p>
                      “Our members are so impressed. It's intuitive. It's clean.
                      It's distraction free. If you're building a community.
                    </p>
                  </div>
                  <div class="ud-testimonial-info">
                    <div class="ud-testimonial-image">
                      <img
                        src={require("./images/testimonials/author-02.png")}
                        alt="author"
                      />
                    </div>
                    <div class="ud-testimonial-meta">
                      <h4>Margin Gesmu</h4>
                      <p>Founder @Lineicons</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div
                  class="ud-single-testimonial wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div class="ud-testimonial-ratings">
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                    <i class="lni lni-star-filled"></i>
                  </div>
                  <div class="ud-testimonial-content">
                    <p>
                      “Our members are so impressed. It's intuitive. It's clean.
                      It's distraction free. If you're building a community.
                    </p>
                  </div>
                  <div class="ud-testimonial-info">
                    <div class="ud-testimonial-image">
                      <img
                        src={require("./images/testimonials/author-03.png")}
                        alt="author"
                      />
                    </div>
                    <div class="ud-testimonial-meta">
                      <h4>William Smith</h4>
                      <p>Founder @GrayGrids</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ud-brands wow fadeInUp" data-wow-delay=".2s">
                  <div class="ud-title">
                    <h6>Trusted and Used by</h6>
                  </div>
                  <div class="ud-brands-logo">
                    <div class="ud-single-logo">
                      <img
                        src={require("./images/brands/ayroui.svg")}
                        alt="ayroui"
                      />
                    </div>
                    <div class="ud-single-logo">
                      <img
                        src={require("./images/brands/uideck.svg")}
                        alt="uideck"
                      />
                    </div>
                    <div class="ud-single-logo">
                      <img
                        src={require("./images/brands/graygrids.svg")}
                        alt="graygrids"
                      />
                    </div>
                    <div class="ud-single-logo">
                      <img
                        src={require("./images/brands/lineicons.svg")}
                        alt="lineicons"
                      />
                    </div>
                    <div class="ud-single-logo">
                      <img
                        src={require("./images/brands/ecommerce-html.svg")}
                        alt="ecommerce-html"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- ====== Testimonials End ====== -->*/}

        {/*<!-- ====== Contact Start ====== -->*/}
        <section id="contact" class="ud-contact">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-7">
                <div class="ud-contact-content-wrapper">
                  <div class="ud-contact-title">
                    <span>CONTACT US</span>
                    <h2>
                      <br />
                      Want to know more about the RydzFleet Management platform?
                    </h2>
                  </div>
                  <div class="ud-contact-info-wrapper">
                    <div class="ud-single-info">
                      <div class="ud-info-icon">
                        <i class="lni lni-map-marker"></i>
                      </div>
                      <div class="ud-info-meta">
                        <h5>Our Location</h5>
                        <p>
                          Frugal Scientific Pvt ltd
                          {/* <p>
                            #301/302, 3rd Floor, Saket Callipolis, Sarjapur Main Rd, Doddakannelli, Bengaluru, Karnataka 560035
                           
                          </p> */}
                          <p>   #677, 1st Floor, 13th Cross Road, 27th Main Rd, 1st
                            Sector, HSR Layout, Bengaluru, Karnataka 560102,
                            India</p>
                        </p>
                      </div>
                    </div>
                    <div class="ud-single-info">
                      <div class="ud-info-icon">
                        <i class="lni lni-envelope"></i>
                      </div>
                      <div class="ud-info-meta">
                        <h5>Contact Us</h5>
                        <p>Phone:+91 9324962887</p>
                        <p>Mail:rydzfleet@frugalscientific.com </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5">
                <div
                  class="ud-contact-form-wrapper wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h3 class="ud-contact-form-title">Get In Touch</h3>
                  <form>
                    <Form.Group>
                      <Form.Label className="contactenquiry">Full Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="name"
                        value={value.name}
                        onChange={this.onChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    {errors.name.length > 0 && (
                      <span
                        style={{ color: 'red', fontSize: '12px' }}
                        className="error">
                        {errors.name}
                      </span>
                    )}
                    <Form.Group>
                      <Form.Label className="contactenquiry">Email*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="email"
                        name="email"
                        value={value.email}
                        onChange={this.onChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    {errors.email.length > 0 && (
                      <span
                        style={{ color: 'red', fontSize: '12px' }}
                        className="error">
                        {errors.email}
                      </span>
                    )}
                    <Form.Group>
                      <Form.Label className="contactenquiry">Phone*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="mobileNo"
                        name="mobileNo"
                        value={value.mobileNo}
                        onChange={this.onChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    {errors.mobileNo.length > 0 && (
                      <span
                        style={{ color: 'red', fontSize: '12px' }}
                        className="error">
                        {errors.mobileNo}
                      </span>
                    )}
                    <Form.Group>
                      <Form.Label className="contactenquiry">Message*</Form.Label>
                      <Form.Control
                        type="textArea"
                        placeholder="message"
                        name="message"
                        value={value.message}
                        onChange={this.onChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    {errors.message.length > 0 && (
                      <span
                        style={{ color: 'red', fontSize: '12px' }}
                        className="error">
                        {errors.message}
                      </span>
                    )}
                    <Button
                      className="ud-main-btn"
                      style={{ width: '100%', marginTop: 10 }}
                      //  size="lg"
                      onClick={this.submit}>
                      Send Enquiry
                    </Button>
                  </form>
                  {/* <form class="ud-contact-form">
                    <div class="ud-form-group">
                      <label for="fullName">Full Name*</label>
                      <input
                        type="text"
                        id="fullname"
                        name="fullName"
                        placeholder="your name"
                        data-error="Name is required."
                        required="required"
                      />
                    </div>
                    <div class="ud-form-group">
                      <label for="email">Email*</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="your email"
                      />
                    </div>
                    <div class="ud-form-group">
                      <label for="phone">Phone*</label>
                      <input type="text" name="phone" placeholder="phone" />
                    </div>
                    <div class="ud-form-group">
                      <label for="message">Message*</label>
                      <textarea
                        name="message"
                        rows="1"
                        placeholder="Type your message here"
                      ></textarea>
                    </div>
                    <div class="ud-form-group mb-0">
                      <button type="submit" class="ud-main-btn">
                        Submit Enquiry
                      </button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*} <!-- ====== Contact End ====== -->*/}
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
}
export default Home;
