export const FETCH_ACTIVE_RQST_INIT_ACTION = "FETCH_ACTIVE_RQST_INIT_ACTION";
export const FETCH_ACTIVE_RQST_FAIL_ACTION =
  FETCH_ACTIVE_RQST_INIT_ACTION + "_ERROR";
export const FETCH_ACTIVE_RQST_SUCCESS_ACTION =
  FETCH_ACTIVE_RQST_INIT_ACTION + "_SUCCESS";

export const FETCH_RQST_HISTORY_ACTION = "FETCH_ORDER_HISTORY_ACTION";
export const FETCH_RQST_HISTORY_SUCCESS_ACTION =
  FETCH_RQST_HISTORY_ACTION + "_SUCCESS";
export const FETCH_RQST_HISTORY_FAIL_ACTION =
  FETCH_RQST_HISTORY_ACTION + "_ERROR";

export const RQST_VIEWPCKG_INIT_ACTION = "RQST_VIEWPCKG_INIT_ACTION";
export const RQST_VIEWPCKG_SUCCESS_ACTION =
  RQST_VIEWPCKG_INIT_ACTION + "_SUCCESS";
export const RQST_VIEWPCKG_FAIL_ACTION = RQST_VIEWPCKG_INIT_ACTION + "_ERROR";

export const CANCEL_INIT_ACTION = "CANCEL_INIT_ACTION";
export const CANCEL_FAIL_ACTION = CANCEL_INIT_ACTION + "_ERROR";
export const CANCEL_SUCCESS_ACTION = CANCEL_INIT_ACTION + "_SUCCESS";

export function fetchActiveRequest(value) {
  return {
    type: FETCH_ACTIVE_RQST_INIT_ACTION,
    value: value,
  };
}

export function fetchRqstHistory(value) {
  return {
    type: FETCH_RQST_HISTORY_ACTION,
    value: value,
  };
}

export function requsetViewPkg(value) {
  return {
    type: RQST_VIEWPCKG_INIT_ACTION,
    payload: value,
  };
}

export function cancelRqst(value) {
  return {
    type: CANCEL_INIT_ACTION,
    payload: value,
  };
}
export function requesthistorySlectedPage(value) {
  return {
    type: FETCH_RQST_HISTORY_ACTION,
    value: value,
  };
}
export function requestactiveSlectedPage(value) {
  return {
    type:  FETCH_ACTIVE_RQST_INIT_ACTION,
    value: value,
  };
}
