export const FETCH_AUTO_DRIVER_INIT_ACTION = "FETCH_AUTO_DRIVER_INIT_ACTION"
export const FETCH_AUTO_DRIVER_SUCCESS_ACTION = FETCH_AUTO_DRIVER_INIT_ACTION + '_SUCCESS'
export const FETCH_AUTO_DRIVER_FAIL_ACTION = FETCH_AUTO_DRIVER_INIT_ACTION + '_ERROR'

export const FETCH_NEARBY_AUTODRIVER_ACTION =
    'FETCH_NEARBY_AUTODRIVER_ACTION'
export const FETCH_NEARBY_AUTODRIVER_FAIL_ACTION =
    FETCH_NEARBY_AUTODRIVER_ACTION + '_ERROR'
export const FETCH_NEARBY_AUTODRIVER_SUCCESS_ACTION =
    FETCH_NEARBY_AUTODRIVER_ACTION + '_SUCCESS'

export const SEARCH_AUTOUSER_INIT_ACTION = 'SEARCH_AUTOUSER_INIT_ACTION'
export const SEARCH_AUTOUSER_FAIL_ACTION = SEARCH_AUTOUSER_INIT_ACTION + '_ERROR'
export const SEARCH_AUTOUSER_SUCCESS_ACTION = SEARCH_AUTOUSER_INIT_ACTION + '_SUCCESS'

export const FETCH_AUTO_QUICKRQST_ACTION = 'FETCH_AUTO_QUICKRQST_ACTION'
export const FETCH_AUTO_QUICKRQST_SUCCESS_ACTION =
    FETCH_AUTO_QUICKRQST_ACTION + '_SUCCESS'
export const FETCH_AUTO_QUICKRQST_FAIL_ACTION =
    FETCH_AUTO_QUICKRQST_ACTION + '_ERROR'

export function fetchAutoDrivers(value) {
    return {
        type: FETCH_AUTO_DRIVER_INIT_ACTION,
        payload: value
    }
}

export function filterbyLoc(value) {
    return {
        type: FETCH_NEARBY_AUTODRIVER_ACTION,
        payload: value
    }
}

export function userSearch(value) {
    return {
        type: SEARCH_AUTOUSER_INIT_ACTION,
        payload: value
    }
}

export function autoquickride(data) {
    return {
        type: FETCH_AUTO_QUICKRQST_ACTION,
        payload: data
    }
}