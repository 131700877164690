
import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
    ADD_DRIVER_INIT_ACTION,
    ADD_DRIVER_SUCCESS_ACTION,
    ADD_DRIVER_FAIL_ACTION,
    VERIFY_DRIVER_OTP_INIT_ACTION,
    VERIFY_DRIVER_OTP_SUCCESS_ACTION,
    VERIFY_DRIVER_OTP_FAIL_ACTION,
    DRIVER_SEND_OTP_ACTION,
    DRIVER_SEND_OTP_SUCCESS_ACTION,
    DRIVER_SEND_OTP_FAIL_ACTION,
    RESEND_OTP_INIT_ACTION,
    RESEND_OTP_SUCCESS_ACTION,
    RESEND_OTP_FAIL_ACTION
} from './actions'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

function* addDriver(action) {
    try {
        const mobileNo = `+91${action.payload.mobileNo}`
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/users/findDriverWithMobNo/${mobileNo}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            toast.error('There is no driver available for this mobile number', {
                autoClose: 3000
            })
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: ADD_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: ADD_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* verifyDriver(action) {
    try {
        const data = {
            driverId: action.payload.driverId,
            pin: action.payload.otp.otpNo
        }
        const token = localStorage.getItem('userdata')
        const res = yield fetch(`${appConfig.ip}/web/driverLink/checkpin/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const resJSON = yield res.json()
        if (!resJSON.ok) {
            toast.error('Invalid OTP. Please try again')
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('Driver attached successfully', {
                autoClose: 3000
            })
            yield put({
                type: VERIFY_DRIVER_OTP_SUCCESS_ACTION
            })
            yield put(push("/cabs"))
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: VERIFY_DRIVER_OTP_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* sendOtp(action) {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(`${appConfig.ip}/web/driverLink/sendotp/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ driverId: action.payload.id })
        })
        if (!res.ok) {
            // if (res.status === '404') {
            //     toast.error('Driver already attached', {
            //         autoClose: 3000
            //     })
            //     yield put({
            //         type: VERIFY_DRIVER_OTP_SUCCESS_ACTION
            //     })
            // }
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: DRIVER_SEND_OTP_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            if (err.status === 404) {
                toast.error('Driver already attached ', {
                    autoClose: 3000
                })
            }
            yield put({ type: DRIVER_SEND_OTP_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* resendOtp(action) {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(`${appConfig.ip}/web/driverLink/resendotp`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ driverId: action.payload })
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('OTP has been resent successfully', {
                autoClose: 3000
            })
            const resJSON = yield res.json()
            yield put({
                type: RESEND_OTP_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: RESEND_OTP_FAIL_ACTION, error: err })
        } else {
        }
    }
}

export function* attachDriver() {
    yield takeEvery(ADD_DRIVER_INIT_ACTION, addDriver)
    yield takeEvery(VERIFY_DRIVER_OTP_INIT_ACTION, verifyDriver)
    yield takeEvery(DRIVER_SEND_OTP_ACTION, sendOtp)
    yield takeEvery(RESEND_OTP_INIT_ACTION, resendOtp)
}