export const ADD_AGREEMENT_INIT_ACTION = "ADD_AGREEMENT_INIT_ACTION"
export const ADD_AGREEMENT_SUCCESS_ACTION = ADD_AGREEMENT_INIT_ACTION + '_SUCCESS'
export const ADD_AGREEMENT_FAIL_ACTION = ADD_AGREEMENT_INIT_ACTION + '_ERROR'

export const FETCH_AGREEMENT_INIT_ACTION = "FETCH_AGREEMENT_INIT_ACTION"
export const FETCH_AGREEMENT_SUCCESS_ACTION = FETCH_AGREEMENT_INIT_ACTION + '_SUCCESS'
export const FETCH_AGREEMENT_FAIL_ACTION = FETCH_AGREEMENT_INIT_ACTION + '_ERROR'

export const EDIT_AGREEMENT_INIT_ACTION = "EDIT_AGREEMENT_INIT_ACTION"
export const EDIT_AGREEMENT_SUCCESS_ACTION = EDIT_AGREEMENT_INIT_ACTION + '_SUCCESS'
export const EDIT_AGREEMENT_FAIL_ACTION = EDIT_AGREEMENT_INIT_ACTION + '_ERROR'

export function addAgreemnt(value) {
    return {
        type: ADD_AGREEMENT_INIT_ACTION,
        payload: value
    }
}

export function fetchAgreemnt(value) {
    return {
        type: FETCH_AGREEMENT_INIT_ACTION,
        value: value
    }
}

export function editAgreemnt(value) {
    return {
        type: EDIT_AGREEMENT_INIT_ACTION,
        payload: value
    }
}
export function agreementSlectedPage(value) {
    return {
      type: FETCH_AGREEMENT_INIT_ACTION,
      value: value
    };
  }