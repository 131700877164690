import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/es/storage';
import store, { history } from '../boot/configureStore';

import loginReducer1 from '../containers/LoginContainer/reducer'
import cabsReducer from '../containers/CabsContainer/reducer'
import attachDrvrReducer from '../containers/AttachDriverContainer/reducer'
import fleetProfReducer from '../containers/FleetProfileContainer/reducer'
import detachDrvrReducer from '../containers/DetachDriverContainer/reducer'
import requestReducer from '../containers/RequestContainer/reducer';
import autoReducer from '../containers/AutoContainer/reducer';
import dashboardReducer from '../containers/DashboardContainer/reducer';
import agreemntReducer from '../containers/AgreementContainer/reducer';
import forgotReducer from '../containers/ForgotPasswordContainer/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loginReducer1,
    cabsReducer,
    attachDrvrReducer,
    fleetProfReducer,
    detachDrvrReducer,
    requestReducer,
    autoReducer,
    dashboardReducer,
    agreemntReducer,
    forgotReducer
  });

export default createRootReducer;
