import * as React from 'react'

import Home from '../../screens/Home'
import { connect } from 'react-redux'
import { sendMsg } from './action'




class HomeContainer extends React.Component<Props, State> {
    render() {
        return <Home history={this.props.history} sendMsg={this.props.sendMsg} />
    }
}

function bindAction(dispatch) {
    return {
        sendMsg: (value) => {
            dispatch(sendMsg(value))
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(HomeContainer)
