import { clr } from './clr'
export const styles = {
    tableHead: {
        backgroundColor: clr.aboutbg
    },
    fleetBtn: {
        backgroundColor: clr.btnColor,
        borderColor: clr.btnColor
    },
    fleetBtnCncl: {
        backgroundColor: clr.cancelBtn,
        borderColor: clr.cancelBtn
    }


}