import React, { Component } from "react";
import "./style.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form, Row, Col, Image, Button, Carousel, Container, Card } from 'react-bootstrap'
import { styles } from './style.js'
import Footer from "../../components/Footer";
import Navbar3 from "../../components/Navbar2";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)
const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(val => val.length > 0 && (valid = false))
  return valid
}
const validphoneRegex = RegExp(/^\d{10}$/)
// const validateForms = errors => {
//   let valid = true
//   Object.values(errors).forEach(val => val.length > 0 && (valid = false))
//   return valid
// }
class Pricing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // showError: false,
      value: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
        city: '',
        subject: ''
      },
      errors: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
        city: '',
        subject: ''
      }
    }
    this.onChange = this.onChange.bind(this)
    this.submit = this.submit.bind(this)
  }
  onChange(values) {

    const { value, name } = values.target
    this.setState({
      value: { ...this.state.value, [name]: value }

      // showError: false
    })

    let errors = this.state.errors

    switch (name) {
      case 'name':
        errors.name = value.length < 3 ? 'Name must be 3 characters long' : ''
        break
      case 'mobileNo':
        errors.mobileNo = validphoneRegex.test(value)
          ? ''
          : 'Mobile number is not valid'
        break
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid'
        break
      case 'message':
        errors.message = value.length < 3 ? 'Message is required' : ''
        break
      case 'city':
        errors.city = value.length < 3 ? 'City is required!' : ''
        break
      case 'subject':
        errors.subject = value.length < 3 ? 'Subject is required!' : ''
        break

      default:
        break
    }
    this.setState({ errors, [name]: value })
  }
  submit(event) {
    event.preventDefault()
    if (
      validateForm(this.state.errors) &&
      this.state.value.name !== '' &&
      this.state.value.mobileNo !== '' &&
      this.state.email !== '' &&
      this.state.message !== '' &&
      this.state.city !== '' &&
      this.state.subject !== ''
    ) {
      let data = this.state.value
      this.props.sendEnquiry(data)
      this.setState({
        value: {
          name: '',
          mobileNo: '',
          email: '',
          message: '',
          city: '',
          subject: ''
        }
      })

    } else {

      toast.error('Please enter valid datas', {
        autoClose: 2000
      })
    }


  }

  render() {
    const { errors } = this.state
    let value = this.state.value
    return (
      <div className="aboutpagefullres">
        <Navbar3 />
        <div className="aboutmain" style={styles.abutpag}>
          <div className="submain">
            <Container>
              <div className="subdiv">
                <Row>
                  {/*<Col xs={12} sm={12} md={6} lg={6}>

                    {this.props.location.state.role === 'fleet' && (
                      <>
                        <Card style={{ width: '18rem' }}>

                          <Card.Body>
                            <Card.Title>Rydz Fleet</Card.Title>

                            <Card.Text>
                              <h6>Normal Package Rydz Fleet</h6>
                              <h6>Rs.500</h6>
                            </Card.Text>
                            <Button style={{ width: '100%', marginTop: 20, backgroundColor: 'rgb(69, 177, 55)', color: '#fff', borderColor: 'green' }} href="#">Select Plan</Button>

                          </Card.Body>

                        </Card>
                        <Card style={{ width: '18rem' }}>

                          <Card.Body>
                            <Card.Title>Rydz Fleet</Card.Title>

                            <Card.Text>
                              <h6>Normal Package Rydz Fleet</h6>
                              <h6>Rs.500</h6>
                            </Card.Text>
                            <Button style={{ width: '100%', marginTop: 20, backgroundColor: 'rgb(69, 177, 55)', color: '#fff', borderColor: 'green' }} href="#">Select Plan</Button>

                          </Card.Body>

                        </Card>
                      </>
                    )}
                    {this.props.location.state.role === 'service' && (
                      <>
                        <Card style={{ width: '18rem' }}>
                          <Card.Body>
                            <Card.Title>Rydz Service</Card.Title>

                            <Card.Text>
                              <h6>Normal Package Rydz Service</h6>
                              <h6>Rs.500</h6>
                            </Card.Text>
                            <Button style={{ width: '100%', marginTop: 20, backgroundColor: 'rgb(69, 177, 55)', color: '#fff', borderColor: 'green' }} href="#">Select Plan</Button>
                          </Card.Body>
                        </Card>
                        <Card style={{ width: '18rem' }}>
                          <Card.Body>
                            <Card.Title>Rydz Service</Card.Title>

                            <Card.Text>
                              <h6>Normal Package Rydz Service</h6>
                              <h6>Rs.500</h6>
                            </Card.Text>
                            <Button style={{ width: '100%', marginTop: 20, backgroundColor: 'rgb(69, 177, 55)', color: '#fff', borderColor: 'green' }} href="#">Select Plan</Button>
                          </Card.Body>
                        </Card>
                      </>
                    )}


                  </Col>*/}
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Row>
                      <Col xs={12} sm={12} md={2} lg={2}></Col>

                      <Col
                        xs={12}
                        sm={9}
                        md={12}
                        lg={8}
                        className="contactusform"
                        style={styles.nav}>
                        <div>
                          <h1 className="contactusformhead">Enquiry Form</h1>
                          <div className="contactusformlabel">
                            <form>
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  name="name"
                                  value={value.name}
                                  onChange={this.onChange}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.name.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.name}
                                </span>
                              )}
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Phone number"
                                  value={value.mobileNo}
                                  name="mobileNo"
                                  onChange={this.onChange}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.mobileNo.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.mobileNo}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  value={value.email}
                                  onChange={this.onChange}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.email.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.email}
                                </span>
                              )}
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  value={value.city}
                                  onChange={this.onChange}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.city.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.city}
                                </span>
                              )}
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  name="subject"
                                  placeholder="Subject"
                                  value={value.subject}
                                  onChange={this.onChange}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.subject.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.subject}
                                </span>
                              )}
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <textarea
                                  className="form-control"
                                  rows="2"
                                  id="comment"
                                  name="message"
                                  placeholder="Type a message"
                                  value={value.message}
                                  onChange={this.onChange}
                                  autoComplete="off"></textarea>
                              </Form.Group>
                              {errors.message.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.message}
                                </span>
                              )}


                            </form>
                            <div>
                              {/* <Button
                                className="contactusformbtn"
                                onClick={this.submit}>
                                Send Enquiry
                              </Button> */}
                              <Button
                                style={{ width: '100%', marginTop: 20, backgroundColor: 'rgb(69, 177, 55)', color: '#fff', height: 60, borderColor: 'green' }}

                                onClick={this.submit}>
                                Send Enquiry
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={1} lg={1}></Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        {/* <div className="teammain">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h1 className="appdownloadhead">
                  Download the Mobile App for free
                </h1>
                <p className="appdownloadpera">
                  We have separate mobile app for Riders and Drivers. You can
                  freely download the same on Playstore and AppStore.
                </p>
                <Row>
                  {/*<Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="appriderlogo"
                      src={require('../../assets/img/logo-rider.png')}
                    />
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://play.google.com/store/apps/details?id=com.frugal.rydzrider">
                        {' '}
                        <Image
                          className="appplaystoreImg"
                          src={require('../../assets/img/app1.png')}
                          alt="Third slide"
                        />
                      </a>

                    </div>
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://apps.apple.com/us/app/rydz-taxi/id1515691114">
                        <Image
                          className="appiosplaystoreImg"
                          src={require('../../assets/img/app.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                            </Col>*/}
        {/*<Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="appdriverlogo"
                      src={require('../../assets/img/logo-driver.png')}
                    />
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://play.google.com/store/apps/details?id=com.frugal.rydzdriver">
                        <Image
                          className="appplaystoreImg"
                          src={require('../../assets/img/app1.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://apps.apple.com/us/app/rydz-driver-app/id1515691389">
                        <Image
                          className="appiosplaystoreImg"
                          src={require('../../assets/img/app.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/*<Col xs={12} sm={12} md={6} lg={6}>
                <div className="contactcarou">
                  <Carousel>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz9.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz9.jpeg')}
                      /> 
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz6.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz6.jpeg')}
                      /> 

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz7.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz7.jpeg')}
                      /> 

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz8.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz8.jpeg')}
                      /> 
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz5.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz5.jpeg')}
                      /> 
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
        </div>*/}


      </div>
    )
  }
}

export default Pricing;
