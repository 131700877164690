import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import {
    ATTACHED_DRIVER_INIT_ACTION,
    ATTACHED_DRIVER_SUCCESS_ACTION,
    ATTACHED_DRIVER_FAIL_ACTION,
    DETACH_DRIVER_INIT_ACTION,
    DETACH_DRIVER_SUCCESS_ACTION,
    DETACH_DRIVER_FAIL_ACTION
} from './actions'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

function* fetchAttachedDrivers(value) {
    try {
        const token = localStorage.getItem('userdata')
        let pageno = !value.value
        ? 1
        : value.value.page === undefined
        ? 1
        : value.value.page;
      let searchquery = !value.value
        ? ""
        : value.value === undefined
        ? ""
        : value.value.searchquery;
        const res = yield fetch(
            `${appConfig.ip}/web/geoLocs/getNearestAttachedDrivers?search=${searchquery}&limit=10&page=` + pageno,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: ATTACHED_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: ATTACHED_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* detachDriver(action) {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(`${appConfig.ip}/web/driverLink/unlink/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ driverId: action.payload.userDtls.id })
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('Driver Detached Sucessfully', {
                autoClose: 3000
            })
            const resJSON = yield res.json()
            yield put({
                type: DETACH_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
            yield put({
                type: ATTACHED_DRIVER_INIT_ACTION
            })
            // yield put({
            //   type: FETCH_ATTACHED_AUTO_DRIVER_INIT_ACTION,

            // })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: DETACH_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}


export function* detachDriverAction() {
    yield takeEvery(ATTACHED_DRIVER_INIT_ACTION, fetchAttachedDrivers)
    yield takeEvery(DETACH_DRIVER_INIT_ACTION, detachDriver)
}
