import {
    ATTACHED_DRIVER_INIT_ACTION,
    ATTACHED_DRIVER_SUCCESS_ACTION,
    ATTACHED_DRIVER_FAIL_ACTION
} from './actions'


const initialState = {
    processing: false,
    attachedDrvr: null
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case ATTACHED_DRIVER_INIT_ACTION:
            return {
                ...state,
                processing: true,

            }
        case ATTACHED_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                attachedDrvr: action.payload.rows,
                attachedDrvrcount: action.payload.count
            }

        case ATTACHED_DRIVER_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                error: true,

            }
        default:
            return {
                ...state
            }
    }
}