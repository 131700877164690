


import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'

import {
    ADD_AGREEMENT_INIT_ACTION,
    ADD_AGREEMENT_SUCCESS_ACTION,
    ADD_AGREEMENT_FAIL_ACTION,
    FETCH_AGREEMENT_INIT_ACTION,
    FETCH_AGREEMENT_SUCCESS_ACTION,
    FETCH_AGREEMENT_FAIL_ACTION,
    EDIT_AGREEMENT_INIT_ACTION,
    EDIT_AGREEMENT_SUCCESS_ACTION,
    EDIT_AGREEMENT_FAIL_ACTION
} from './action'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

function* addAgreemnt(action) {
    try {
        const token = localStorage.getItem('userdata')
        const userId = localStorage.getItem('userId')

        const res1 = yield fetch(`${appConfig.ip}/web/users/findDriverWithMobNo/+91${action.payload.mobileNo}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            // body: JSON.stringify(data)
        })
        if (!res1.ok) {
            let errJSON = {}
            try {
                errJSON = yield res1.json()
            } catch { }
            throw Object.assign(res1, errJSON)
        } else {
            const resJSON1 = yield res1.json()
            const data = new FormData()
            data.append('name', 'AgreemntPic')
            data.append('AgreemntPic', action.payload.docs, 'AgreemntPic')
            const res2 = yield fetch(`${appConfig.imgServerip}/agreementPic/`, {
                method: 'POST',
                headers: {
                    // Accept: 'application/json',
                    //  'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                // mode: 'no-cors',
                body: data
            })
            if (!res2.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res2.json()
                } catch { }
                throw Object.assign(res2, errJSON)
            } else {
                const resJSON2 = yield res2.json()
                let data = {
                    name: action.payload.name,
                    mobileNo: `+91${action.payload.mobileNo}`,
                    make: action.payload.make,
                    model: action.payload.model,
                    remark: action.payload.remark,
                    userId: userId,
                    doc: resJSON2.files[0],
                    driverId: resJSON1.id

                }
                const res = yield fetch(`${appConfig.ip}/agreement`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(data)
                })
                if (!res.ok) {
                    let errJSON = {}
                    try {
                        errJSON = yield res.json()
                    } catch { }
                    throw Object.assign(res, errJSON)
                } else {
                    yield toast.success('Agreement Added Sucessfully', {
                        autoClose: 2000
                    })
                    const resJSON = yield res.json()
                    yield put({
                        type: FETCH_AGREEMENT_INIT_ACTION,
                    })
                    yield put({
                        type: ADD_AGREEMENT_SUCCESS_ACTION,
                        payload: resJSON
                    })
                }
            }
        }
    }
    catch (err) {
        if (err.ok === false) {
            yield put({
                type: ADD_AGREEMENT_FAIL_ACTION,
                error: err
            })
        }
        if (err.status === 404) {
            yield toast.error('Driver not found,check the mobile number', {
                autoClose: 2000
            })
        }
    }
}

function* fetchAgreemnt(value) {
    try {
        const token = localStorage.getItem('userdata')
        const userId = localStorage.getItem('userId')
        let pageno = !value.value
        ? 1
        : value.value.page === undefined
        ? 1
        : value.value.page;
      let searchquery = !value.value
        ? ""
        : value.value === undefined
        ? ""
        : value.value.searchquery;
        const res = yield fetch(`${appConfig.ip}/agreement?userId=${userId}&search=${searchquery}&limit=10&page=` + pageno, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: FETCH_AGREEMENT_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: FETCH_AGREEMENT_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* editAgreemnt(action) {
    try {

        const token = localStorage.getItem('userdata')
        const userId = localStorage.getItem('userId')
        if (action.payload.docs !== undefined) {
            const data = new FormData()
            data.append('name', 'AgreemntPic')
            data.append('AgreemntPic', action.payload.docs, 'AgreemntPic')
            const res2 = yield fetch(`${appConfig.imgServerip}/agreementPic/`, {
                method: 'POST',
                headers: {
                    // Accept: 'application/json',
                    //  'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                // mode: 'no-cors',
                body: data
            })
            if (!res2.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res2.json()
                } catch { }
                throw Object.assign(res2, errJSON)
            } else {
                const resJSON2 = yield res2.json()
                let data = {
                    name: action.payload.name,
                    mobileNo: `${action.payload.mobileNo}`,
                    make: action.payload.make,
                    model: action.payload.model,
                    remark: action.payload.remark,
                    userId: userId,
                    doc: resJSON2.files[0],
                    driverId: action.payload.driverId

                }
                const res = yield fetch(`${appConfig.ip}/agreement/${action.payload.id}`, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(data)
                })
                if (!res.ok) {
                    let errJSON = {}
                    try {
                        errJSON = yield res.json()
                    } catch { }
                    throw Object.assign(res, errJSON)
                } else {
                    yield toast.success('Agreement Added Sucessfully', {
                        autoClose: 2000
                    })
                    const resJSON = yield res.json()
                    yield put({
                        type: EDIT_AGREEMENT_SUCCESS_ACTION,
                        payload: resJSON
                    })
                    yield put({
                        type: FETCH_AGREEMENT_INIT_ACTION,
                    })
                }
            }
        } else {
            let data = {
                name: action.payload.name,
                mobileNo: `${action.payload.mobileNo}`,
                make: action.payload.make,
                model: action.payload.model,
                remark: action.payload.remark,
                userId: userId,
                doc: action.payload.doc,
                driverId: action.payload.driverId

            }
            const res = yield fetch(`${appConfig.ip}/agreement/${action.payload.id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                yield toast.success('Agreement Added Sucessfully', {
                    autoClose: 2000
                })
                const resJSON = yield res.json()
                yield put({
                    type: EDIT_AGREEMENT_SUCCESS_ACTION,
                    payload: resJSON
                })
                yield put({
                    type: FETCH_AGREEMENT_INIT_ACTION,
                })
            }
        }
    }
    catch (err) {
        if (err.ok === false) {
            yield put({
                type: EDIT_AGREEMENT_FAIL_ACTION,
                error: err
            })
        }
        if (err.status === 404) {
            yield toast.error('Driver not found,check the mobile number', {
                autoClose: 2000
            })
        }
    }
}

export function* agreement() {
    yield takeEvery(ADD_AGREEMENT_INIT_ACTION, addAgreemnt)
    yield takeEvery(FETCH_AGREEMENT_INIT_ACTION, fetchAgreemnt)
    yield takeEvery(EDIT_AGREEMENT_INIT_ACTION, editAgreemnt)
}