import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { styles } from './style.js'
import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <footer class="ud-footer wow fadeInUp" data-wow-delay=".15s">
        <div class="shape shape-1">
          <img src={require("../../assets/img/footer/shape-1.svg")} alt="shape" />
        </div>
        <div class="shape shape-2">
          <img src={require("../../assets/img/footer/shape-2.svg")} alt="shape" />
        </div>
        <div class="shape shape-3">
          <img src={require("../../assets/img/footer/shape-3.svg")} alt="shape" />
        </div>
        <div class="ud-footer-widgets">
          <div class="container">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="ud-widget">
                  <a href="index.html" class="ud-footer-logo">
                    <img src={require("../../assets/img/Asset 9.png")} alt="logo" />
                  </a>
                  <p class="ud-widget-desc">
                    Open Platform For Connecting Drivers Any Time, Any Where

                  </p>
                  <ul class="ud-widget-socials">
                    <li>
                      <a href="https://www.facebook.com/taxirydz">
                        <i class="lni lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i class="lni lni-twitter-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/rydz_app/">
                        <i class="lni lni-instagram-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i class="lni lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div class="ud-widget">
                  <h5 class="ud-widget-title">About Us</h5>
                  <ul class="ud-widget-links">
                    <li>
                      <a href="/home#home">Home</a>
                    </li>
                    <li>
                      <a href="/home#pricing">Pricing</a>
                    </li>
                    <li>
                      <a href="/home#about">About</a>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                <div class="ud-widget">
                  <h5 class="ud-widget-title">Useful Links</h5>
                  <ul class="ud-widget-links">
                    {/*<li>
                      <a href="/home#features">How it works</a>
                    </li>*/}
                    <li>
                      <a href="https://www.rydz.in/privacy">Privacy policy</a>
                    </li>
                    <li>
                      <a href="https://www.rydz.in/terms">Terms of service</a>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                <div class="ud-widget">
                  <h5 class="ud-widget-title">Our Products</h5>
                  <ul class="ud-widget-links">

                    <li>
                      <a
                        href="https://www.rydz.in/"
                        rel="nofollow noopner"
                        target="_blank"
                      >Rydz </a>
                      
                      </li>
                    <li>
                      <a
                        href="https://rydzfleet.com/"
                        rel="nofollow noopner"
                        target="_blank"
                      >Rydz Fleet</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://rydzfleet.com/"
                        rel="nofollow noopner"
                        target="_blank"
                      >Rydz Services</a>

                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-8 col-sm-10">
                <div class="ud-widget">
                  <h5 class="ud-widget-title">Get Our Mobile Apps</h5>
                  <ul class="ud-widget-brands">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.frugal.rydzdriver"
                        rel="nofollow noopner"
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/img/footer/brands/playstore.png")}
                          alt="playstore"
                        />
                      </a>
                    </li>


                    <li>
                      <a
                        href="https://apps.apple.com/us/app/rydz-taxi/id1515691114"
                        rel="nofollow noopner"
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/img/footer/brands/appstore.png")}
                          alt="appstore"
                        />
                      </a>
                    </li>

                  </ul>
                     
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ud-footer-bottom">
<div class="container">
<div class="row">
<div class="col-md-12">
<ul class="ud-footer-bottom-left">
<li class="ud-footer-bottom-right">

© All Rights Reserved | Designed and Developed by
<li><a href="https://frugalscientific.com/" rel="nofollow"> Frugal Scientific Pvt Ltd</a></li>
</li>
</ul>
</div>
</div>
</div>
</div>
    </footer>
    )
  }
}
export default Footer
